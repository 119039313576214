body {
  background-color: #f8f9fa !important;
}
  .HowToPage {
    position: absolute;
    background-color: #f8f9fa;
    top: 0;
    width: 100vw;
    height: auto;
  }

  .HowToPage-content {
    position: relative;
    top: 15vh;
    padding-bottom: 20vh;
  }
  
  .card {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .card:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
  .card-img-top {
    width: 100%;
    height: auto;
  }

  .HowToPage-cardHeader {
    background-color: white !important;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card-text {
    color: #6c757d;
  }

  .HowToPage-content .HowToPage-content-title {
    width: 70%;
    text-align: left;
    margin-bottom: 40px;
    font-size: 1.5rem;
    color: #212529;
  }

  .typeIcon {
    margin-right: 10px;
  }

  .HowToPage-content-introductionText {
    margin-bottom: 30px;
  }

  .ModalTutorialUnderConstruction {
    background: white !important;
    border-radius: 10px !important;
    padding: 10px;
    width: 50%;
    height: 50vh;
    margin: 25vh auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ModalTutorialUnderConstruction .modalText {
    color: black;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .modalConstructionIcon {
    font-size: 60px;
    margin-bottom: 40px;
    color: rgba(0,0,0,0.7)
  }

  .modalForm {
    align-items: center;
    justify-content: center !important;
    margin-top: 30px;
  }

  .modalFormInput {
    height: 100%;
    padding-left: 10px;
  }

  .modalSubmitButton {
    margin-left: 20px;
    margin-top: 0px;
    position: relative;
    top: -2px;
  }

  .dropdown-button {
    position: absolute;
  }

  .dropdown-button .btn-primary {
    background-color: black;
    border-color: black;
    margin-bottom: 20px;
    position: absolute !important;
    right: 0;
    top: 0;
    padding-right: 20px;
  }

  .HowToPage-introductionRow {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  /* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {
  .HowToPage-content {
      width: 90% !important;
      top: 8vh;
  }

  .ModalTutorialUnderConstruction {
    width: 90%;
    height: auto;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .ModalTutorialUnderConstruction .modalText {
    font-size: 14px;
  }

  .dropdown-button .btn-primary {
    background-color: black;
    border-color: black;
    margin-bottom: 20px;
    position: absolute !important;
    right: -15px;
    top: 0;
    padding-right: 20px;
  }
}