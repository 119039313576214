.BlackboxDashboard {
    width: 100%;
}

.BlackboxDashboard h1 {
  font-size: 28px;
  width: 85%;
  margin-left: 2%;
}

.dashboard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    background-color: rgb(34, 34, 42);
    padding: 20px;
    width: 100%;
  }
  
  .tile {
    background-color: rgb(41, 41, 51);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  }
  
  h2 {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 20px;
    color: white;
  }

  .chart-container {
    width: 100%;
    height: 200px;
}