.BlackboxExperiments {
  width: 90%;
  min-height: 78vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.BlackboxExperiments-titleRow,
.BlackboxExperiments-chartRow {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.BlackboxExperiments-titleRow h1 {
  font-size: 24px;
  width: 85%;
}

.BlackboxExperiments-titleRow h1 span {
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.BlackboxExperiments-titleRow-filterButton {
  width: 12%;
  height: 40px;
  background: #292933;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: 15px;
  margin-left: 3%;
}

.BlackboxExperiments-titleRow-filterButton-text {
  margin-left: 10px;
  color: #ffffff;
}

.BlackboxExperiments-titleRow-dropdown {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #292933;
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  min-width: 250px;
  z-index: 1000;
}

.BlackboxExperiments-titleRow-dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;
  text-align: left;
  background-color: #292933;
  color: white;
  border: none;
  width: 100%;
  text-align: left;
  margin-bottom: 4px;
  border-radius: 4px;
  font-size: 14px;
}

.BlackboxExperiments-titleRow-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
  
  .BlackboxExperiments-experimentTile {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    margin: 30px auto;
    margin-top: 0px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .BlackboxExperiments-experimentTile-conceptTile {
    width: 70%;
    height: 100%;
    background: #292933;
    margin: 0px auto;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
  }
  
  .BlackboxExperiments-experimentTile-conceptTile-vsCircle {
    width: 50px;
    height: 50px;
    background: black;
    color: white;
    border: solid white 2px;
    border-radius: 90px;
    position: absolute;
    top: 38px;
    left: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 800;
  }
  
  .BlackboxExperiments-experimentTile-conceptTile-description {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }
  
  .BlackboxExperiments-experimentTile-conceptTile-description h4 {
    margin: 15px 0px;
    font-size: 18px;
  }

  .BlackboxExperiments-experimentTile-conceptTile-description p {
    margin: 0px;
    font-size: 14px;
  }
  
  .BlackboxExperiments-experimentTile-conceptKeyInfo {
    width: 29%;
    height: 100%;
    margin: 0px auto;
    margin-left: 1%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .BlackboxExperiments-experimentTile-conceptKeyInfo-row {
    width: 31%;
    height: 42%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .BlackboxExperiments-experimentTile-conceptKeyInfo-row-item {
    width: 100%;
    height: 100%;
    background: #292933;
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter-menu {
    position: absolute;
    width: 100%;
    background-color: #292933;
    border: none;
    border-radius: 4px;
    padding: 10px;
    margin-top: 5px;
    z-index: 1;
    position: absolute;
    top: 40px;
  }

  .experimentTileHrefLink {
    all: unset;
    cursor: pointer;
  }

  .experimentTileHrefLink:hover {
    all: unset;
    cursor: pointer;
  }