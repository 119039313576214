.BlackboxTool-content-header {
    width: 82%;
    margin: 0vh auto;
    padding: 4vh 0vh 2vh 0vh !important;
    height: 6vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    right: 0px;
    z-index: 2;
    background: rgb(34,34,42);
    box-sizing: content-box;
}

.customTheme .BlackboxTool-content-header  {
    background: rgb(247, 168,0);
}

.BlackboxTool-content-header.menuInActive {
    width: 100%;
}

.BlackboxTool-content-header-menuButton {
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.MenuOpenButton {
    color: #fff;
    background: rgba(0,0,0,0.8);
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    z-index: 4;
    font-size: 24px;    
  }

.customTheme .MenuOpenButton {
    background: rgba(46, 46, 56, 1);
}

.BlackboxTool-content-header-searchBar {
    width: 80%;
    position: relative;
}

.BlackboxTool-content-header-searchBar.menuActive {
    left: 60px;
}

.BlackboxTool-content-header-searchBar-inputField {
    all: unset;
    width: 96%;
    height: 40px;
    background: rgba(0,0,0,0.5);
    border-radius: 10px;
    color: white;
    padding-left: 60px;
}

.customTheme .BlackboxTool-content-header-searchBar-inputField {
    background: rgba(46, 46, 56, 1);
}

.BlackboxTool-content-header-searchBar-searchIcon {
    position: absolute;
    height: 18px;
    left:20px;
    top: 11px;
    color:rgba(255,255,255, 0.6);
}


.BlackboxTool-content-header-searchBar-submitButton {
    position: absolute;
    right: 20px;
    top: 5px;
    height: 30px;
    width: 30px;
    background: rgba(0,0,0,1);
    border-radius: 100%;
    border: none;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 200;
}

.BlackboxTool-content-header-buildDeckAndQuestionMark {
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.BlackboxTool-content-header-buildDeckAndQuestionMark.moveToRightWhenOnHomePage {
    width: 94%;
    margin-right: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.BlackboxTool-content-header-buildDeckAndQuestionMark div {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    color: white;
    border-radius: 90px;
    background: rgba(0,0,0, 0.6);
    border: 2px solid rgba(255,255,255, 1);
    cursor: pointer;
}

.BlackboxTool-content-header-buildDeckAndQuestionMark div:first-child {
    position: relative;
    right: 15px;
}

.BlackboxTool-content-header-buildDeckAndQuestionMark div img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}


/* all chatbot modal related styling when question is asked */

.BlackboxAskMeAnything-responseModal .modal-dialog {
    max-width: 50% !important;  /* Or you can set the desired width with px, like 800px */
}

.BlackboxAskMeAnything-responseModal-body-response h1 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 20px;
    color: white !important;
}

.BlackboxAskMeAnything-responseModal-body-response h2 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 20px;
    color: white !important;
}

.BlackboxAskMeAnything-responseModal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
}

.BlackboxAskMeAnything-responseModal .modal-content {
    background: rgb(41,41,51);
    color: white;
    padding: 20px !important;
}

.BlackboxAskMeAnything-responseModal-header {
    width: 100%;
}



@media (max-width: 900px) {
    .BlackboxTool-content-header {
        width: 100%;
        margin: 0vh auto;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        z-index: 2;
        background: rgb(34,34,42);
        box-sizing: content-box;
    }

    .BlackboxTool-content-header-menuButton {
        margin-left: 8%;
    }

    .BlackboxTool-content-header-searchBar {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        order: 3;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .BlackboxTool-content-header-searchBar-inputField {
        all: unset;
        width: 90%;
        height: 40px;
        margin-left: 0%;
        background: rgba(0,0,0,0.5);
        border-radius: 10px;
        color: white;
        padding-left: 80px;
        box-sizing: border-box;
    }

    .BlackboxTool-content-header-searchBar-submitButton {
        position: absolute;
        right: 30px;
        top: 5px;
        height: 30px;
        width: 30px;
        background: rgba(0,0,0,1);
        border-radius: 100%;
        border: none;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .BlackboxTool-content-header-searchBar-searchIcon {
        left: 9%;
    }

    .BlackboxTool-content-header-user {
        margin-right: 8%;
    }

    .BlackboxTool-content-header-buildDeckAndQuestionMark {
        width: auto;
    }

    .BlackboxTool-content-header-buildDeckAndQuestionMark div {
        position: relative;
        right: 25px;
    }

    .BlackboxTool-content-header-buildDeckAndQuestionMark div:first-child {
        position: relative;
        right: 35px;
    }

    /* all chatbot modal related styling when question is asked */

   
    .BlackboxAskMeAnything-responseModal {
        width: 100%;
        max-width: 100%;
    }

    .wide-modal .modal-dialog {
        max-width: 90% !important;  /* Or you can set the desired width with px, like 800px */
        width: 90%;
    }

    .BlackboxAskMeAnything-responseModal .modal-content {
        background: rgb(41,41,51);
        color: white;
        margin-left: 10%;
        margin-top: 10%;
        margin-bottom: 10%;
        padding: 5px !important;
    }

    .BlackboxTool-content-header-buildDeckAndQuestionMark.moveToRightWhenOnHomePage {
        position: absolute;
        top: 10px;
        width: 97%;
        margin-right: 3%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}