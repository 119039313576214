.mekkoGraph {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60vh;
  margin: 20px auto;
  background: black;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 80px;
}

.mekkoGraph-categoryColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: auto;
}

.mekkoGraph-categoryColumn-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mekkoGraph-categoryColumn-item-label {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 10px;
  padding: 3px 10px;
  font-size: 8px;
}

.mekkoGraph-categoryColumn-categoryLegend {
  margin-top: 20px;
  height: auto;
  text-align: center;
}

.mekkoGraph-categoryColumn-item-label.inactive {
  display: none;
}