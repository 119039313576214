.referenceCaseContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 20px;
  background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%); 
}
.referenceCaseFlightPlan {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90vh;
  margin: 5vh auto;
  font-family: "Poppins", sans-serif;
}

.referenceCaseFlightPlan-titleBlock {
    width: 24%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.referenceCaseFlightPlan-titleBlock-content {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    border-right: dashed 2px rgba(255,255,255,0.5);
    border-bottom: dashed 2px rgba(255,255,255,0.5);
    padding-bottom: 30px;
}

.referenceCaseFlightPlan-titleBlock-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    margin-right: 30px;
    color: #F6C6AD;
    text-align: left;
}

.referenceCaseFlightPlan-titleBlock-content p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: white;
    text-align: left;
    margin-right: 30px;
}

.titleBlockLineContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    bottom: -10px;
    left: 0px;
}

.referenceCaseFlightPlan-titleBlock .titleBlockLineContainer div.horizontalLine {
    width: 30%;
    background-color: #F6C6AD;
    height: 20px;
    margin-top: 20px;
}

.referenceCaseFlightPlan-titleBlock .titleBlockLineContainer div.horizontalDottedLine {
    width: 70%;
    border-bottom: rgba(255,255,255,0.5) 2px dashed;
    height: 20px;
    margin-top: 20px;
    position: relative;
    bottom: 10px;
}



.referenceCaseFlightPlan-steps {
    width: 38%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: relative;
}

.verticalDottedLine.step1to3.line1 {
    width: 2px;
    height: 50%;
    border-left: dashed 2px rgba(255,255,255,0.5);
    position: absolute;
    top:0px;
    left: 0px;
}

.horizontalDottedLine.step1to3.line2 {
    width: 26%;
    height: 2px;
    border-top: dashed 2px rgba(255,255,255,0.5);
    position: absolute;
    top:0%;
    left: 0px;
}

.verticalDottedLine.step1to3.line3 {
    width: 2px;
    height: 100%;
    border-left: dashed 2px rgba(255,255,255,0.5);
    position: absolute;
    bottom:0%;
    left: 26%;
}

.horizontalDottedLine.step1to3.line4 {
    width: 74%;
    height: 2px;
    border-top: dashed 2px rgba(255,255,255,0.5);
    position: absolute;
    bottom:-2px;
    left: 27%;
}

.verticalDottedLine.step1to3.line5 {
    width: 2px;
    height: 100%;
    border-right: dashed 2px rgba(255,255,255,0.5);
    position: absolute;
    bottom:0%;
    right: -10px;
}

.horizontalDottedLine.step1to3.line6 {
    width: 24%;
    height: 2px;
    border-top: dashed 2px rgba(255,255,255,0.5);
    position: absolute;
    top:0%;
    left: 2%;
}

.verticalDottedLine.step1to3.line7 {
    width: 2px;
    height: 90%;
    border-right: dashed 2px rgba(255,255,255,0.5);
    position: absolute;
    top:0%;
    left: 26%;
}


.referenceCaseFlightPlan-steps-stepContainer {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.referenceCaseFlightPlan-steps-stepContainer-stepNumber {
    font-size: 4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: white;
    width: 12%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 5%;
}

.referenceCaseFlightPlan-steps-stepContainer-stepDeviderLine {
    width: 2.4%;
    height: 70%;
    background-color: #F6C6AD;
    margin-right: 20px;
    z-index: 1;
}

.referenceCaseFlightPlan-steps-stepContainer-stepContent {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.referenceCaseFlightPlan-steps-stepContainer-stepContent h5 {
    font-size: 1.2rem;
    color: white;
    margin: 10px 0px;
    text-align: left;
}

.referenceCaseFlightPlan-steps-stepContainer-stepContent p {
    font-size: 0.8rem;
    color: white;
    margin-bottom: 10px;
    text-align: left;
}

/* Height Changes ---------------------------------------------------------------------------*/
@media all and (max-height: 750px) {
    

    .referenceCaseFlightPlan-steps-stepContainer-stepContent p {
        font-size: 12px;
    }
}

/* Height Changes ---------------------------------------------------------------------------*/
@media all and (max-height: 650px) {
    

    .referenceCaseFlightPlan-steps-stepContainer-stepContent p {
        font-size: 10px;
    }
}

/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {
    
.referenceCaseContainer {
    display: none;
}

}