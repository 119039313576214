.BlackboxTargetAudiences {
    width: 90%;
    min-height: 78vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .BlackboxTargetAudiences-titleRow,
  .BlackboxTargetAudiences-chartRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .BlackboxTargetAudiences-titleRow h1 {
    font-size: 28px;
    width: 85%;
  }
  
  .BlackboxTargetAudiences-titleRow h1 span {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
    
    .BlackboxTargetAudiences-audienceTile {
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: row;
      margin: 30px auto;
      margin-top: 0px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
    }
    
    .BlackboxTargetAudiences-audienceTile-conceptTile {
      width: 80%;
      height: 100%;
      background: #292933;
      margin: 0px auto;
      position: relative;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
    }
    
    .BlackboxTargetAudiences-audienceTile-conceptTile-vsCircle {
      width: 50px;
      height: 50px;
      background: black;
      color: white;
      border: solid white 2px;
      border-radius: 90px;
      position: absolute;
      top: 38px;
      left: 78px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 800;
    }
    
    .BlackboxTargetAudiences-audienceTile-conceptTile-description {
      display: flex;
      flex-direction: row;
      padding: 0px 20px;
      justify-content: space-between;
    }

    .BlackboxTargetAudiences-audienceTile-conceptTile-description div {
      flex-direction: column;
      width: 70%;
    }
    
    .BlackboxTargetAudiences-audienceTile-conceptTile-description div h4 {
      margin: 15px 0px;
      font-size: 18px;
      width: 100%;
    }
  
    .BlackboxTargetAudiences-audienceTile-conceptTile-description div p {
      margin: 0px;
      font-size: 14px;
      width: 100%;
    }

    .BlackboxTargetAudiences-audienceTile-conceptTile-description-generativeAICreate {
      flex-direction: column;
      width: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .BlackboxTargetAudiences-audienceTile-conceptTile-description-generativeAICreate h4.needInspirationTitle {
      width: 60%;
      font-size: 14px;
      font-weight: bold;
    }

    .BlackboxTargetAudiences-audienceTile-conceptTile-description-generativeAICreate button {
      all: unset;
      width: 60%;
      margin: 0px;
      margin-bottom: 10px;
      border-radius: 5px;
      background: black;
      color: white;
      font-size: 12px;
      padding: 5px 0px;
      text-align: center;
      cursor: pointer;
    }
    
    .BlackboxTargetAudiences-audienceTile-callToActionButtons {
      width: 19%;
      height: 100%;
      margin: 0px auto;
      margin-left: 1%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }

    .BlackboxTargetAudiences-audienceTile-callToActionButtons h5 {
        margin: 0px;
        margin-top: -3px;
        margin-bottom: 3px;
        width: 90%;
        text-align: left;
        font-size: 12px;
    }

    .BlackboxTargetAudiences-audienceTile-callToActionButtons button {
        all: unset;
        background:#292933;
        color: white;
        text-align: center;
        width: 90%;
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .BlackboxTargetAudiences-audienceTile-callToActionButtons button:hover {
        background:rgba(0, 0, 0, 0.5);
    }

    .BlackboxTargetAudiences-audienceTile-callToActionButtons span {
        font-size: 10px;
        color: black;
        font-weight: 800;
        background-color: lightgrey;
        width: 15px;
        border-radius: 2px;
        margin-right: 8px;
        margin-left: 8px;
        font-size: 10px;
    }

    @media (max-width:900px) {
      .BlackboxTargetAudiences-audienceTile {
        flex-direction: column;
        height: auto;
        width: 100%;
      }
      .BlackboxTargetAudiences-audienceTile-conceptTile {
        flex-direction: column;
        width: 100%;
        padding: 20px;
        background: red;
      }

      .BlackboxTargetAudiences-audienceTile-conceptTile-description {
        width: 100%;
        flex-direction: column;
        background: blue;
      }

      .BlackboxTargetAudiences-audienceTile-conceptTile-description-content {
        width: 100% !important;
        flex-direction: column;
        background: pink;
      }

      .BlackboxTargetAudiences-audienceTile-conceptTile-description-content h4 {
        width: 100%;
        flex-direction: column;
      }
      .BlackboxTargetAudiences-audienceTile-conceptTile-description-content p {
        width: 100%;
        flex-direction: column;
        background: orange
      }

      .BlackboxTargetAudiences-audienceTile-conceptTile-description-generativeAICreate {
        width: 100% !important;
        flex-direction: column;
        margin-right: 0px;
      }

      .needInspirationTitle {
        width: 100% !important;
      }

      .BlackboxTargetAudiences-audienceTile-conceptTile-description-generativeAICreate button{
        width: 100%;
      }

    .BlackboxTargetAudiences-audienceTile-callToActionButtons {
      flex-direction: column;
      width: 100%;
      margin: 10px 0px;
    }

    .BlackboxTargetAudiences-audienceTile-callToActionButtons h5 {
      width: 100%;
      text-align: left;
      margin: 15px 0px;
    }

    .BlackboxTargetAudiences-audienceTile-callToActionButtons button {
      width: 100%;
      margin: 0px;
      margin-bottom: 10px;
    }

    .swiper-slide {
      width: 100%; /* Adjust this value to the desired width of each slide */
      height: 100%;
    }
  
  }