.BlackboxTool-content-experiment {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.BlackboxTool-content-experiment-titleRow, .BlackboxTool-content-experiment-chartRow{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.BlackboxTool-content-experiment-titleRow h1 {
    font-size: 24px;
    width: 85%;
}

.BlackboxTool-content-experiment-titleRow h1 span {
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }

.BlackboxTool-content-experiment-titleRow-filterButton {
    width: 12%;
    height: 40px;
    background: #292933;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    top: 15px;
    margin-left: 3%;
}

.BlackboxTool-content-experiment-titleRow-filterButton-text {
    margin-left: 10px;
}

.BlackboxTool-content-experiment-overviewRow {
    width: 100%;
    height: 140px;
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto;
    margin-top: 0px;
}

.BlackboxTool-content-experiment-overviewRow-conceptTile {
    width: 70%;
    height: 100%;
    background: #292933;
    margin: 20px auto;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.BlackboxTool-content-experiment-overviewRow-conceptTile-vsCircle {
    width: 50px;
    height:50px;
    background: black;
    color: white;
    border: solid white 2px;
    border-radius: 90px;
    position: absolute;
    top: 45px;
    left: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 800;
}

.BlackboxTool-content-experiment-overviewRow-conceptTile p {
    padding: 0px 20px;
}

.BlackboxTool-content-experiment-overviewRow-conceptKeyInfo {
    width: 29%;
    height: 100%;
    margin: 20px auto;
    margin-left: 1%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: row;
}

.BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row-item {
    width: 100%;
    height: 100%;
    margin: 0px 5px;
    background: #292933;
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BlackboxTool-content-experiment-chartRow-chartTile {
    border-radius: 10px;
    width: 48%;

    height: 300px;
    background: #292933;
    margin: 20px auto;
    position: relative;
}

.BlackboxTool-content-experiment-chartRow-chartTile h3 {
    position: absolute;
    padding: 0px 20px;
    top: 0px;
    z-index: 1;
    font-size: 18px;
}

.BlackboxTool-content-experiment-chartRow-chartTile-prosCons {
    margin-top: 50px;
    padding: 20px 30px;
}

.BlackboxTool-content-experiment-chartRow-chartTile-prosCons div {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.BlackboxTool-content-experiment-chartRow-chartTile-prosCons-pro-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightgreen;
    color: black;
}

.BlackboxTool-content-experiment-chartRow-chartTile-prosCons-con-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightcoral;
    color: black;
}

.BlackboxTool-content-experiment-chartRow-chartTile-prosCons-pro-text {
    width: 100%;
    margin-left: 20px;
}