/* LandingPage.css */
.SevenStepPageMobile {
    display: none;
  }

  /* Mobile ---------------------------------------------------------------------------*/
  @media all and (max-width: 1350px) {

    .SevenStepPageMobile {
      font-family: 'Montserrat', sans-serif;
      color: white;
      background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
      backdrop-filter: blur(10px);
      width: 100%;
      margin: 0px auto;
      box-sizing: border-box;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100% !important;
      box-sizing: border-box !important;
    }

    .SevenStepMobile-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 80% !important;
      margin: 100px auto;
      box-sizing: border-box;
    }

    .SevenStepMobile-section-mainTitle {
      font-weight: bolder;
    }
  
    .SevenStepMobile-section-mainTitle-5ways{
      font-size: 36px;
      color: rgba(255,255,255,0.8);
      font-weight: bolder;
    }

    .SevenStepMobile-section h2 {
      margin-bottom: 26px;
    }

    .SevenStepMobile-section-tile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px;
      background: rgb(31,31,33);
      box-sizing: border-box;
      padding: 20px;
      width: 95%;
      height: 50vh;
      margin-bottom: 5vh;
    }
    
    .SevenStepMobile-section-tile-number {
      font-size: 4em;
      color: rgba(255, 255,255,0.8);
    }
    
    .SevenStepMobile-section-tile-title {
      font-size: 1em;
      font-weight: bold;
      margin-bottom: 10px;
    }
    
    .SevenStepMobile-section-tile-description {
      text-align: left;
      color: rgba(255,255,255,0.7);
      font-weight: 300;
      margin-bottom: 30px;
      font-family: 'calibri';
      font-size: 0.9vw;
      letter-spacing: 0.17vw;
      width: 100%;
   
    }

    .SevenStepMobile-section-tile-description {
      font-size: 13px;
      letter-spacing: 2px;
    }

    .swiper-pagination-bullet {
      background-color: white;
    }
  }