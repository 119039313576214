/* Laptop ---------------------------------------*/

.BlackboxValueDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 50px;
    margin-bottom: 50px;
}


.BlackboxValueDescription-textHolder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    order:1;
}

.BlackboxValueDescription-textHolder.switch{
    order:2;
}

.BlackboxValueDescription-imageHolder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    order:2;
}

.BlackboxValueDescription-imageHolder.switch{
    order:1;
}

.BlackboxValueDescription-imageHolder-image{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E9A992;
    border-radius: 5px;
    box-shadow: 20px 20px rgb(252, 196,175);
    width: 70%;
    height: auto;
}

.BlackboxValueDescription-textHolder-text{
    display: flex;
    flex-wrap:wrap;
    justify-content: left;
    align-content: center;
    text-align: left;
    width: 70%;
    height: 100%;
}

.BlackboxValueDescription-textHolder-text h1 {
    width: 100%;
    margin-bottom: 10px;
    letter-spacing: 0.17vw;
    font-size: 1.5vw;
    font-weight: bolder;
    font-family: 'Poppins', 'Montserrat', 'sans-serif';
}

.BlackboxValueDescription-textHolder-text p {
    width: 100%;
    margin-top: 10px;
    letter-spacing: 0.15vw;
    font-size: 0.85vw;
}


/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {
    .BlackboxValueDescription {
        flex-wrap: wrap;
        height: auto;
        padding-bottom: 100px;
    }

    .BlackboxValueDescription.switch {
        background-color: rgb(248,248,248);
    }
    
    .BlackboxValueDescription-textHolder{
        height: auto;
        width: 100%;
        order:2;
    }
    .BlackboxValueDescription-imageHolder{
        align-items:flex-end;
        width: 100%;
        height: 50%;
        order: 1;
    }

    .BlackboxValueDescription-imageHolder-image{
        width: 64vw;
        height: 48vw;
        margin-top: 100px;
    }

    .BlackboxValueDescription-textHolder-text {
        width: 90%;
        position: relative;
        margin: 0px auto;
    }

    .BlackboxValueDescription-textHolder-text h1 {
        letter-spacing: 4px;
        font-size: 22px;
        text-align: center;
        margin: 30px auto;
        width: 90%;
    }

    .BlackboxValueDescription-textHolder-text p {
        letter-spacing: 2px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        width: 90%;
        margin: 10px auto;
    }
}
