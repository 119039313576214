.BlackboxCompetitorCollection {
    width: 100%;
    min-height: 200vh;
}

.BlackboxCompetitorCollection-titleRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .BlackboxCompetitorCollection-titleRow-title {
    font-size: 28px;
    width: 100%;
    box-sizing: border-box;
  }

.BlackboxCompetitorCollection-rowForFilterAndSortButtons {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
}

.BlackboxCompetitorCollection-rowForFilterAndSortButtons-filterButtons {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row !important;
}

.BlackboxCompetitorCollection-rowForFilterAndSortButtons-filterButtons .dropdownFilters {
    margin-right: 10px;
}

.dropdownFilters button {
    background-color: black;
    border: none;
}

.dropdownFilters button:hover {
    background-color: rgb(250,115,85);
    border: none;
}

.customTheme .dropdownFilters button {
    background: rgb(205,32,38);
}

.dropdownFilters.active-filter button {
    background-color: rgb(250,115,85);
    border: none;
}

.customTheme .dropdownFilters.active-filter button {
    background: rgb(205,32,38);
}

.shareSKUButton {
    background-color: rgb(250,115,85);
    border: none;
    margin-right: 15px;
}

.customTheme .shareSKUButton {
    background: rgb(205,32,38);
}

.shareSKUButton:hover {
    background-color: rgb(230,95,65);
    border: none;
    margin-right: 15px;
}

.customTheme .shareSKUButton:hover {
    background: rgb(180,12,18);
}

.BlackboxCompetitorCollection-rowForFilterAndSortButtons-sortButtons {
    width: 20%;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-end;
}

.sortBySalesButton {
    background: rgba(0, 0, 0, 0);
    border: none;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.sortBySalesButton.sortActive {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
}

.sortBySalesButton:hover {
    background: rgba(0, 0, 0, 0);
}

.sortBySalesButton div {
    margin-right: 20px;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card {
    width: 95%;
    margin: 20px auto;
    background: black;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;
}

.customTheme .BlackboxCompetitorCollection-rowForCollectionOfCards-card {
    background: rgba(46, 46, 56, 1);
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-imageContainer img {
    width: 100%;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    width: 70%;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-titleContainer h5 {
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    width: 30%;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button {
    background: rgb(41,41,51);
    border: none;
}

.customTheme .BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button {
    background: rgb(0,0,0,0.8);
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button:hover {
    background: rgb(250,115,85);
    border: none;
}

.customTheme .BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button:hover {
    background: rgb(205,32,38);
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-readMoreContentContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    width: 100%;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-readMoreContentContainer div {
    padding: 5px 10px;
    font-size: 12px;
    width: 100%;
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


@media all and (max-width: 900px) {

    .BlackboxCompetitorCollection {
        width: 90%;
        margin: 10px auto;
    }

    .BlackboxCompetitorCollection-MekkoGraph {
        display: none;
        width: 300px !important;
        margin: 0 auto;
    }
    
    .BlackboxCompetitorCollection-rowForFilterAndSortButtons {
        display: flex;
        flex-direction: column;
        margin: 10px auto;
        width: 100%;
    }

    .BlackboxCompetitorCollection-rowForFilterAndSortButtons-filterButtons {
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 10px auto;
        width: 100% !important;
    }

    .BlackboxCompetitorCollection-rowForFilterAndSortButtons-filterButtons .dropdownFilters {
        margin: 10px auto;
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        width: 100% !important;
    }

    .BlackboxCompetitorCollection-rowForFilterAndSortButtons-filterButtons .dropdownFilter .dropdownItem {
        width: 100% !important;
    }
      

    .BlackboxCompetitorCollection-rowForFilterAndSortButtons-sortButtons {
        width: 100% !important;
        margin: 10px auto;
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
    }
}



