.BlackboxHomePage {
    width: 90%;
    margin-top: 10vh;
    height: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.BlackboxHomePage.videoPage .FlyingCheezItInBackground {
    position: absolute;
    width: 160px;
    z-index: 0;
}

.BlackboxHomePage.videoPage .FlyingCheezItInBackground.CheezIt1 {
    top: -100px;
    left: 0%;
    z-index: 1000;
}

.BlackboxHomePage.videoPage .FlyingCheezItInBackground.CheezIt2 {
    top: 100px;
    right: 10%;
}

.BlackboxHomePage.videoPage .FlyingCheezItInBackground.CheezIt3 {
    top: 500px;
    left: -10%;
}

.BlackboxHomePage.videoPage h1 {
    margin-bottom: 10px;
    text-align: center;
    z-index: 1;
}

.IntroTextOfHomePage {
    width: 100%;
    text-align: center;
}

.BlackboxHomePage-IntroTextContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 10px;
    z-index: 1;
}

.BlackboxHomePage-IntroTextContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10px;
}

 /* Toggle button for dark mode */
 .toggle-button {
    position: relative;
  }
  
  .Stealth {
    background-color: black; /* Black Background Color */
    width: 84px;
    height: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    position: absolute;
    left: -25px;
  }

  .Cheez-It {
    background-color: rgb(205,32,38); /* Red Background Color */
    width: 84px;
    height: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    align-items: center;
    position: absolute;
    right: -25px;
  }


.learnMoreButtonRowVideoHomePage {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    bottom: 20px;
    right: 0px;
}

Button.learnMoreButtonVideoHomePage {
    background: black;
    border: none;
    margin-right: 20px;
}

Button.learnMoreButtonVideoHomePage:hover {
    background: Black;
    border: none;
}

.BlackboxHomePage Form {
    position: relative;
    width: 100%;
}

.BlackboxHomePage-form-questionInputField {
    width: 100%;
    font-size: 20px;
    background: rgba(0,0,0,0);
    border: solid 1px white;
    border-radius: 10px;
    color: white;
    resize: none;
    padding:20px;
    padding-right: 50px;
}

.BlackboxHomePage-form-questionSubmitButton {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 20px;	
}


.BlackboxHomePage-inspiration.videoPage {
    width: 100%;
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
}

.BlackboxHomePage-inspiration-titleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.BlackboxHomePage-inspiration-titleContainer button {
    position: absolute;
    right: 0;
    bottom: 40px;
    margin-right: 20px;
    background: rgba(0,0,0,0);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.BlackboxHomePage-inspiration-mobile {
    display: none;
}

.BlackboxHomePage-inspiration-title {
    margin-top: 50px;
    font-size: 16px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid rgb(240,240,240);
    padding-bottom: 15px;
    margin-bottom: 20px;
    padding-left: 20px;
    box-sizing: border-box;
}

.BlackboxHomePage-inspiration-title-icon {
    margin-right: 8px;
}

.BlackboxHomePage-inspiration-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.BlackboxHomePage-inspiration-cards.mobile {
    display: none;
}

.videoPage .BlackboxHomePage-inspiration-cards-card {
    width: 32%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgba(0,0,0,0.3);
    color: white;
    border-radius: 10px;
    cursor: pointer;
    text-align: left;
    position: relative;
    padding-bottom: 50px;
}

.BlackboxHomePage.videoPage.customTheme .BlackboxHomePage-inspiration-cards-card {
    background: rgba(46, 46, 56, 1);
}

.BlackboxHomePage-inspiration-cards-card h5 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 10px;
}

.BlackboxHomePage-inspiration-cards-card p {
    font-size: 12px;
    letter-spacing: 2px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-top: 0px;
}

.videoPage .PageInfo-card-modal-readMoreContentSidePannelContainer-content-body p {
    margin-top: 10px;
    margin-bottom: 10px;
}

.BlackboxHomePage-moreQuestions {
    width: 100%;
    margin-bottom: 150px;
}

.customTheme .BlackboxHomePage-form-questionInputField {
    background: rgba(41,41,51,1);
}

.BlackboxHomePage-form-questionSubmitButton {
    border-radius: 90%;
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(250,115,85);
}

.customTheme .BlackboxHomePage-form-questionSubmitButton {
    background-color: rgb(205,32,38);
}
            
              

@media (max-width: 900px) {

    .BlackboxHomePage {
        margin-top: 0vh;
        height: auto;
    }
    .BlackboxHomePage-inspiration-cards {
        display: none;
    }

    .BlackboxHomePage-inspiration-cards.mobile {
        display: flex;
        flex-direction: column;
    }

    .videoPage .BlackboxHomePage-inspiration-cards-card {
        width: 95%;
        margin: 10px auto;
    }

    .BlackboxHomePage-form-questionInputField {
        width: 100%;
    }

    .BlackboxHomePage .swiper-pagination-bullet {
        position: relative;
        top: 15px;
      }
}