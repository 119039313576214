.BlackboxNeeds {
    width: 100%;
}


.BlackboxNeeds-titleRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

.BlackboxNeeds-titleRow-title {
    font-size: 28px;
    width: 50%;
    box-sizing: border-box;
  }

  .BlackboxNeeds-titleRow-buttons {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

.BlackboxNeeds-titleRow-filterButton {
    all: unset;
    cursor: pointer;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background:rgba(41,41,51);
    border-radius: 5px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
}

.BlackboxNeeds-titleRow-shareButton {
    all: unset;
    cursor: pointer;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    background:rgba(41,41,51);
    border-radius: 5px;
    margin-left: 10px;
    height: 40px;
}

.BlackboxNeeds-titleRow-filterButton:hover {
    background: rgba(0,0,0,0.5);
}

.BlackboxNeeds-grid {
    width: 100%;
}

.BlackboxNeeds-grid-tile {
    width: 95%;
    margin: 20px;
    background: rgba(46, 46, 56, 0.9);  /* semi-transparent background */
    backdrop-filter: blur(10px);  /* blur the background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.3);  /* dark border */
    border-radius: 10px 20px;
    box-sizing: border-box;
    padding-bottom: 10px;
}

.BlackboxNeeds-grid-tile-cardInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 50px;
    margin: 0px auto;
    border-bottom: 2px solid black;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
}

.BlackboxNeeds-grid-tile-cardInfo-creator {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.BlackboxNeeds-grid-tile-cardInfo-creator-name {
    height: 100%;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.BlackboxNeeds-grid-tile-cardInfo-creator-avatar {
    width: 30px;
    height: 30px;
    border-radius: 90%;
    border: 1px solid white;
    margin-right: 10px;
}

.BlackboxNeeds-grid-tile-title , .BlackboxNeeds-grid-tile-solution , .BlackboxNeeds-grid-tile-votingButtons, .BlackboxNeeds-grid-tile-score , .BlackboxNeeds-grid-tile-arguments, .BlackboxNeeds-grid-tile-need   {
    padding-left: 20px;
    padding-right: 20px;
}

.BlackboxNeeds-grid-tile-need {
    margin-top: 20px;
}

.BlackboxNeeds-grid-tile-votingSlider {
    padding-left: 20px;
    padding-right: 20px;
}

.BlackboxNeeds-grid-tile-votingSlider input {
    width: 90%;
    height: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    background-size: 200% 100%;
    background-position: right bottom;
    margin-top: 20px;
}

.BlackboxNeeds-grid-tile-votingSlider label {
    margin-left: 10px;
}

.BlackboxNeeds-grid-tile-image {
    padding: 20px;
    padding-bottom: 10px;
}

.BlackboxNeeds-grid-tile-title h4 {
    font-size: 18px;
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 30px;
}

.BlackboxNeeds-grid-tile-image img {
    width: 100%;
}

.BlackboxNeeds-grid-tile-votingButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.BlackboxNeeds-grid-tile-votingButtons button {
    all: unset;
    cursor: pointer;
    width: 48%;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background:rgba(0,0,0,0.5);
}

.BlackboxNeeds-grid-tile-votingButtons button.inactive {
    background:rgba(0,0,0,0.1);
    opacity: 0.5;
}



.BlackboxNeeds-grid-tile-votingButtons button:hover {
    background:rgba(0,0,0,0.9);
}

.BlackboxNeeds-grid-tile-score {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;
    margin-top: 30px;
}

.BlackboxNeeds-grid-tile-score.inactive {
    display: none;
}

.BlackboxNeeds-grid-tile-arguments {
    margin-top: 10px;
}

.BlackboxNeeds-grid-tile-arguments-unfoldButton {
    all: unset;
    cursor: pointer;
    width: 100%;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    background:rgba(0,0,0,0);
}

.BlackboxNeeds-grid-tile-arguments button:hover {
    font-weight: bold;
}

.BlackboxNeeds-grid-tile-arguments-pro-argument, .BlackboxNeeds-grid-tile-arguments-con-argument  {
    display:flex;
    flex-direction: row;
    margin: 10px auto;
}

.BlackboxNeeds-grid-tile-arguments-pro-argument-icon , 
.BlackboxNeeds-grid-tile-arguments-con-argument-icon {
    margin-right: 10px;
}

.BlackboxNeeds-grid-tile-arguments-shareButton {
    all: unset;
    cursor: pointer;
    width: 100%;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    background:rgba(0,0,0,0.5);
    margin-top: 20px;
}
.BlackboxNeeds-shareNeedsModal {
    background: rgba(0,0,0,0.8);
    color: white;
}

.BlackboxSolutions-shareSolutionsModal {
    background: rgba(0,0,0,0.8);
    color: white;
}

.BlackboxNeeds-shareNeedsModal-header,
.BlackboxNeeds-shareNeedsModal-body, 
.BlackboxNeeds-shareNeedsModal-footer  {
    background: rgb(34,34,42);
    color: white;
}

.BlackboxNeeds-shareNeedsModal-body-inputLabel {
    font-size: 12px;
    font-weight: bold;
}

.BlackboxNeeds-shareNeedsModal-header button.close {
    color: #fff !important;
    fill: #fff !important;
    opacity: 1 !important;
}

.BlackboxNeeds-shareNeedsModal-body-inputField {
    background-color: rgba(0,0,0,0.5) !important;
    color: white !important;
    border: none !important;
    margin-bottom: 10px !important;
    height: auto !important; /* Overrides any set height */
    min-height: 0 !important; /* In case Bootstrap or other CSS sets a min-height */
    max-height: none !important; /* In case there's a max height set somewhere */
}

.BlackboxNeeds-shareNeedsModal-footer-submitButton {
    background-color: rgba(0,0,0,0.5) !important;
    color: white !important;
    border-color: black !important;
}

.BlackboxNeeds-shareNeedsModal-footer-submitButton:hover {
    background-color: rgba(0,0,0,0.9) !important;
}

.BlackboxNeeds-grid-tile-votingTitle {
    margin-top: 50px;
    margin-bottom: 10px;
    width: 100%;
}

.BlackboxNeeds-grid-tile-votingTitle p {
    width: 90%;
    margin: 10px auto;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}


/* Modal settings*/
Button.randomNeedGeneratorButton {
    all: unset;
    cursor: pointer;
    padding: 5px 10px;
    background: black;
    color: white;
    margin-left: 50px;
    font-size: 12px;
    position: absolute;
    right: 50px;
    height: 25px;
    border-radius: 5px;
}

Button.randomNeedGeneratorButton:hover {
    background: rgba(0, 0, 0, 0.5);
}

Button.randomNeedGeneratorButton:disabled {
    background: rgba(0, 0, 0, 0.3);
}

Button.randomNeedGeneratorButton:active,
Button.randomNeedGeneratorButton:focus {
    background: black !important;
    color: white;
    outline: none;  /* to remove any default browser outlines */
    box-shadow: none; /* to remove Bootstrap's blue glow */
}



.randomNeedGeneratorButtonIcon {
    margin-right: 10px;
}

/* Styling of the needs tyle - NeedVoting*/

.BlackboxNeeds-grid-tile-votingTitle {
    border-top: solid 2px black;
}

.BlackboxNeeds-grid-tile-votingTitle p {
    margin-top: 20px;
}

/* Styling of the needs tyle - Arguments*/
.BlackboxNeeds-grid-tile-arguments-pro {
    width: 100%;
}

.BlackboxNeeds-grid-tile-arguments-pro-argument {
    width: 100%;
    margin-bottom: 20px;
}

.BlackboxNeeds-grid-tile-arguments-pro-argument-text {
    width: 80%; 
}

.argument-likes {
    display: flex;
    flex-direction: column;
    width: 10%;
    margin: 0px auto;
    margin-left: 5%;
}
.argument-likes button {
    background: rgba(0,0,0,0);
    border: none;
    color: grey;
    width: 30px;
    display: flex;
    flex-direction: row;
}

.argument-likes button.liked {
    border: none;
    color: white;
}


/* Code for spinning load button */

.loadingSpinner {
    margin-right: 6px;
}


@media (max-width:900px) {

    .BlackboxNeeds-titleRow {
        width: 90%;
        margin: 0px auto;
        flex-direction: column;
    }
    
    .BlackboxNeeds-titleRow-title {
        font-size: 24px;
        width: 100%;
      }
    
      .BlackboxNeeds-titleRow-buttons {
        width: 100%;
        margin: 10px auto;
        justify-content: space-between;
        align-items: center;
      }
    
    .BlackboxNeeds-titleRow-filterButton {
        margin-right: auto;
        background: rgba(0,0,0,1);
    }
    
    .BlackboxNeeds-titleRow-shareButton {
        margin-left: auto;
        background: rgba(0,0,0,1);
    }
}