/* LogoCarousel.css */

.LogoCarousel-carousel {
    width: 70%;
    margin: 50px auto;
    overflow: hidden;
}

.LogoCarousel-carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
    height: 100px;
}

.LogoCarousel-carousel-item {
    height: 100%;
    display: flex;
    flex: 0 0 33.3333%;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.LogoCarousel-carousel-item img {
    height: 100%;
}

@media all and (max-width: 1150px) {
    .LogoCarousel-carousel {
        width: 86%;
        margin: 50px auto;
        overflow: hidden;
    } 
    .LogoCarousel-carousel-inner {
        height: 80px;
    }
}
