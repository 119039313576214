/*laptop ---------------------------------------------*/

.landingPage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: rgb(248,248,248);
  padding-bottom: 100px;
}

.landingPage-left{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 50vw;
    background-color: rgb(248,248,248);
    display: flex;
    align-items: center;
}

.landingPage-left-text {
    width: 70%;
    margin-left: 15%;
}

.landingPage-left-text h1 {
    letter-spacing: 2px;
    font-size: 2.2vw !important;
    font-weight: bolder !important;
    margin-bottom: 0.7rem;
    color: rgb(20,20,20);
    font-family: 'Poppins','Montserrat', sans-serif !important;
}

.landingPage-left-text-titleMobile {
    display: none;
}

.landingPage-left-text p {
    margin-top:1rem; 
    line-height: 25px; 
    color: rgb(3,3,3);
    font-size: 1vw;
    letter-spacing: 0.17vw;
    margin-bottom: 30px;    
}

.landingPage-left-text-textMobile {
    display: none;
}

.unstyledLink {
    all: unset !important;
    cursor: pointer;
}

a.howToFlyMailLink {
    all: unset;
    cursor: pointer;
    text-transform: uppercase;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    font-size: 1vw;
    letter-spacing: 2px;
    color: white;
    border-radius: 20px;
    padding: 5px 20px;
}

a.howToFlyMailLink:hover {
    all: unset;
    cursor: pointer;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    text-transform: uppercase;
    font-size: 1vw;
    letter-spacing: 2px;
    color: white;
    margin-top:20px;
    font-weight: bolder;
    border-radius: 20px;
    padding: 5px 20px;
}

button.black-callToAction {
    all: unset;
    cursor: pointer;
    background-color: rgb(3,3,3);
    font-size: 1.2vw;
    letter-spacing: 2px;
    color: white;
    margin-top:10px;
    border-radius: 20px;
    padding: 5px 20px;
}

button.black-callToAction:hover {
    background-color: #F1B39C;
    border-color: #F1B39C;
}

.landingPage-right{
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50vw;
    background: rgb(248,248,248);
}

.landingPage-right-images {
    position: relative;
    height: 100%;
    width: 100%;
}

.landingPage-right-images-heroImageDesktop {
    position: absolute;
    width: 80%;
    top: 15%;
    left: 10%;
}

.landingPage-right-images-heroImageMobile {
    display: none;
}

.landingPage-right-scrollText {
    position: absolute;
    top: 75%;
    left: 5%;
    height: 20%;
    width: 30%;
    display: flex;
    justify-content: right;
    align-content: center;
    flex-wrap: wrap;
}

.landingPage-right-scrollText p {
    font-size: 0.9vw !important;
    letter-spacing: 2px;
    color: black !important;
    text-align: left;
    width: 100%;
    position: relative !important;
    top: 5px !important;
    left: 3px !important;
}

.landingPage-right-scrollText p span {
    color: white;
}

.landingPage-right-scrollText .roundScrollButton {
    all: unset;
    cursor: pointer;
    border: 2px solid black;
    font-size: 1vw;
    letter-spacing: 2px;
    color: black;
    text-align: left;
    border-radius: 90%;
    width: 50px;
    height: 50px;
    background-color: rgba(0,0,0,0);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -30%;
    top: 32%;
}

.landingPage-right-scrollText button.roundScrollButton:hover {
    color: #F1B39C;
}

.landingPage-right-scrollText-mobile {
    display: none;
}

.landingPage-left-text-titleDesktop {
    font-size: 2vw !important;
    z-index: 100 !important;
}

.landingPage-left-text-textDesktop {
    font-size: 1vw !important;
}

.lineUnderMainTitle {
    position: absolute;
    bottom: 1px;
    left: 39%;
    height: 10px;
    width: 29%;
    background: linear-gradient(to right bottom, rgb(200,132,108), rgb(222,161,139));
    z-index: 0 !important;
    border-radius: 5px;
}

.lineUnderMainTitleMobile {
    display: none;
}

/* iPad ---------------------------------------------*/
@media all and (max-width: 1100px) and (min-height:700px) and (max-height: 1400px)  {

    .landingPage {
        height: auto;
    }
  
    .landingPage-left {
        width: 100%;
        position: relative;
        order: 2;
        background-color: transparent;
        z-index:100;
        height: auto;
        min-height: 50vh;
    }

    .landingPage-left-text {
        width: 80%;
        margin-left: 10%;
        justify-content: center;
        text-align: center;
        position: relative;
        bottom: 80px;
    }
    
    .landingPage-left-text h1 {
        letter-spacing: 6px;
        font-size: 22px !important;
        margin:0.7rem auto;
        width: 100%;
        text-transform: uppercase;
        margin-top: 60px;
        font-family: 'Montserrat', sans-serif;
    }

    .landingPage-left-text-titleMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
    }

    .landingPage-left-text-titleDesktop {
        display: none;
        z-index: 100 !important;
    }
    
    .landingPage-left-text h1 .light {
        font-weight: 400;
    }
    
    .landingPage-left-text p {
        letter-spacing: 3px;
        font-size: 15px;
        line-height: 20px;
        margin:0.7rem auto;
        margin-top: 1.2rem;
        width: 100%;   
        text-align: center;
    }

    .landingPage-left-text-textMobile {
        display: flex;
        align-items: center;
    }

    .landingPage-left-text-textMobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .landingPage-left-text-textDesktop {
        display: none;
    }
    
    button.black-callToAction {
        display: none;
    }

    a.howToFlyMailLink {
        display: none;
    }

    .landingPage-right {
        width: 100%;
        position: relative;
        order: 1;
        z-index: 1;
        height: auto;
        min-height: 50vh;
    }

    .landingPage-right-images-heroImageMobile {
        display: block;
        position: absolute;
        top: 0;
        width:100%;
        height: auto;
        background: rgb(248,248,248);
    }

    .landingPage-right-images-heroImageDesktop {
        display: none;
    }



    .landingPage-right-scrollText {
        display:none;
        position: relative;
        width: 60vw;
        left: 40vw;
    }

    .landingPage-right-scrollText p {
        font-size: 12px;
        letter-spacing: 2px;
        color: black;
        text-align: left;
        line-height: 16px;
    }

    .landingPage-right-scrollText p span {
        color: black;
    }

    .roundScrollButton {
        font-size: 14px;
        position: absolute;
        top: 19%;
    }

    .landingPage-right-scrollText-mobile {
        display: flex !important;
        position: relative;
        width: 60vw;
        top: 30px;
        left: 28vw;
    }

    .landingPage-right-scrollText-mobile p {
        font-size: 14px;
        letter-spacing: 2px;
        color: black;
        text-align: left;
        line-height: 16px;
    }

    .landingPage-right-scrollText-mobile p span {
        color: black;
    }

    .landingPage-right-scrollText-mobile .roundScrollButton {
        all: unset;
        cursor: pointer;
        border: 2px solid black;
        letter-spacing: 2px;
        color: black;
        text-align: left;
        border-radius: 90%;
        width: 50px;
        height: 50px;
        background-color: rgba(0,0,0,0);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        position: absolute;
        top: 22%;
        left: -28%;
    }
    .lineUnderMainTitle {
        display: none;
    }
    .lineUnderMainTitleMobile {
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0%;
        height: 9px;
        width: 90%;
        background: linear-gradient(to right bottom, rgb(200,132,108), rgb(222,161,139));
        z-index: 0 !important;
        border-radius: 3px;
    }
   
    .landingPage-left-text-titleMobile {
        margin-top: 400px;
        margin-bottom: 0px;
        
    }

    .landingPage-left-text-titleMobile div {
        font-size: 50px !important;
        font-weight: 800 !important;
    }

    .secondLineUnderMainTitleMobile {
        position: relative;
        bottom: 10px;
    }

    .lineUnderMainTitleMobile {
        position: relative;
        bottom: -50px;
    }

    p.landingPage-left-text-textMobile {
        width: 70%;
    }

    .landingPage-right-scrollText-mobile {
        margin: 0px auto;
    }

    .roundScrollButton {
        position: relative;
        left: -70px !important;
    }

    .genAITitleMobile {
        position: relative;
        bottom: 10px;
    }



}
/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 500px) and (max-height:1000px) {

    .landingPage {
        height: auto;
    }
  
    .landingPage-left {
        width: 100%;
        position: relative;
        order: 2;
        background-color: transparent;
        z-index:100;
        height: auto;
        min-height: 50vh;
    }

    .landingPage-left-text {
        width: 80%;
        margin-left: 10%;
        justify-content: center;
        text-align: center;
        position: relative;
        bottom: 80px;
    }
    
    .landingPage-left-text h1 {
        letter-spacing: 6px;
        font-size: 22px !important;
        margin:0.7rem auto;
        width: 100%;
        text-transform: uppercase;
        margin-top: 60px;
        font-family: 'Montserrat', sans-serif;
    }

    .landingPage-left-text-titleMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
    }

    .landingPage-left-text-titleMobile div {
        font-size: 30px !important;
        font-weight: 800 !important;
    }

    .genAITitleMobile {
        position: relative;
        bottom: 10px;
    }

    .landingPage-left-text-titleDesktop {
        display: none;
        z-index: 100 !important;
    }
    
    .landingPage-left-text h1 .light {
        font-weight: 400;
    }
    
    .landingPage-left-text p {
        letter-spacing: 3px;
        font-size: 15px;
        line-height: 20px;
        margin:0.7rem auto;
        margin-top: 1.2rem;
        width: 100%;   
        text-align: center;
    }

    .landingPage-left-text-textMobile {
        display: flex;
        align-items: center;
    }

    .landingPage-left-text-textMobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .landingPage-left-text-textDesktop {
        display: none;
    }
    
    button.black-callToAction {
        display: none;
    }

    a.howToFlyMailLink {
        display: none;
    }

    .landingPage-right {
        width: 100%;
        position: relative;
        order: 1;
        z-index: 1;
        height: auto;
        min-height: 50vh;
    }

    .landingPage-right-images-heroImageMobile {
        display: block;
        position: absolute;
        top: 0;
        width:100%;
        height: auto;
        background: rgb(248,248,248);
    }

    .landingPage-right-images-heroImageDesktop {
        display: none;
    }



    .landingPage-right-scrollText {
        display:none;
        position: relative;
        width: 60vw;
        left: 40vw;
    }

    .landingPage-right-scrollText p {
        font-size: 12px;
        letter-spacing: 2px;
        color: black;
        text-align: left;
        line-height: 16px;
    }

    .landingPage-right-scrollText p span {
        color: black;
    }

    .roundScrollButton {
        font-size: 14px;
        position: absolute;
        top: 19%;
    }

    .landingPage-right-scrollText-mobile {
        display: flex !important;
        position: relative;
        width: 60vw;
        top: 30px;
        left: 28vw;
    }

    .landingPage-right-scrollText-mobile p {
        font-size: 14px;
        letter-spacing: 2px;
        color: black;
        text-align: left;
        line-height: 16px;
    }

    .landingPage-right-scrollText-mobile p span {
        color: black;
    }

    .landingPage-right-scrollText-mobile .roundScrollButton {
        all: unset;
        cursor: pointer;
        border: 2px solid black;
        letter-spacing: 2px;
        color: black;
        text-align: left;
        border-radius: 90%;
        width: 50px;
        height: 50px;
        background-color: rgba(0,0,0,0);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        position: absolute;
        top: 22%;
        left: -28%;
    }
    .lineUnderMainTitle {
        display: none;
    }
    .lineUnderMainTitleMobile {
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0%;
        height: 9px;
        width: 90%;
        background: linear-gradient(to right bottom, rgb(200,132,108), rgb(222,161,139));
        z-index: 0 !important;
        border-radius: 3px;
    }
}

/* iPhone mini ---------------------------*/

@media all and (max-height: 700px) {

    .landingPage-left-text-titleMobile {
        margin-top: 100px;
        margin-bottom: 0px;
    }

    .landingPage-left-text h1 {
        font-size: 24px;
        margin-top: 10px;
    }

    .landingPage-left-text {
        position: relative;
        top: 0px;
    }

    .landingPage-left-text p {
        font-size: 16px;
    }

    button.black-callToAction {
        font-size: 16px;
    }

    .landingPage-right-images-heroImageMobile {
        position: absolute;
        top: 0px;
    }

    .landingPage-right-scrollText-mobile p {
        font-size: 12px !important;
        line-height: 15px;
        padding-left: 5px;
    }

}