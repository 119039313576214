.BlackboxTool {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    color: white;
    font-family: 'calibri', sans-serif;
    box-sizing: border-box;
}

.BlackboxTool-content {
    min-height: 100vh;
    position: relative;
}

.BlackboxTool-content.menuActive {
    width: 82%;
    background: linear-gradient(90deg, rgb(34,34,42) 0%, rgb(34,34,42) 100%); /* gradient background */
    margin-left: 18%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.customTheme .BlackboxTool-content.menuActive {
    background: rgb(247, 168,0);
}

.BlackboxTool-content.menuInActive {
    width: 100%;
    background: linear-gradient(90deg, rgb(34,34,42) 0%, rgb(34,34,42) 100%); /* gradient background */
    margin-left: 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.customTheme .BlackboxTool-content.menuInActive {
    background: rgb(247, 168,0);
}

.BlackboxTool-content-body {
    width: 90%;
    min-height: 88vh;
    margin: 12vh auto;
    margin-top: 0px;
    padding-top: 5vh;
    padding-bottom: 9vh;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

/* Everything for the page info side modal */

.PageInfo-card-modal {
    position: fixed;
    z-index: 1000;
}

.PageInfo-card-modal-background {
    position: fixed;
    z-index: 1001;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
}

.PageInfo-card-modal-readMoreContentSidePannelContainer {
    position: fixed;
    z-index: 1002;
    top: 0px;
    right: 0px;
    padding: 20px;
    width: 32%;
    height: 100vh;
    background: rgb(41,41,51);
    overflow-y: scroll;
    box-sizing: border-box;
    box-shadow: rgba(30,30,30) 0px 0px 10px 0px;
}

.PageInfo-card-modal-readMoreContentSidePannelContainer-closeButtonContainer {
    position: absolute;
    top: 0px;
    right: 40px;
    font-size: 25px;
    cursor: pointer;
    padding: 20px;
    z-index: 1002;
}

.PageInfo-card-modal-readMoreContentSidePannelContainer-content-title {
    font-size: 20px;
    font-weight: 800;
    width: 90%;
    margin: 0px auto;
    margin-top: 50px;
}

.PageInfo-card-modal-readMoreContentSidePannelContainer-content-title .mainTitle {
    color: rgba(255,255,255,1);
    font-size: 24px;
}

.PageInfo-card-modal-readMoreContentSidePannelContainer-content-title .subTitle {
    color: rgba(255,255,255,1);
    font-size: 20px;
    font-weight: 400;
}

.PageInfo-card-modal-readMoreContentSidePannelContainer-content-body {
    display: flex;
    width: 90%;
    margin: 10px auto;
    flex-direction: column;
    padding-bottom: 60px;
}

.PageInfo-card-modal-readMoreContentSidePannelContainer-content-body p {
    font-size: 14px;
    margin: 0px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.PageInfo-card-modal-readMoreContentSidePannelContainer-content-body img {
    width: 70%;
    border-radius: 10px;
}



/* Everything for the build deck side modal */
.BuildDeck-card-modal {
    position: fixed;
    z-index: 1000;
}

.BuildDeck-card-modal-background {
    position: fixed;
    z-index: 1001;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer {
    position: fixed;
    z-index: 1002;
    top: 0px;
    right: 0px;
    padding: 20px;
    width: 32%;
    height: 100vh;
    background: rgb(41,41,51);
    overflow-y: scroll;
    box-sizing: border-box;
    box-shadow: rgba(30,30,30) 0px 0px 10px 0px;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-closeButtonContainer {
    position: absolute;
    top: 0px;
    right: 40px;
    font-size: 25px;
    cursor: pointer;
    padding: 20px;
    z-index: 1002;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: rgb(34,34,42);
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-image {
    width: 15%; 
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-image img {
  width: 100%;
}

.empty .BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-image img {
    opacity: 0.5;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-content {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-removeItemButtonContainer {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-removeItemButtonContainer Button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 20px;
    z-index: 1002;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-removeItemButtonContainer Button:hover {
    background: rgb(250,115,85);
}

.customTheme .BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-removeItemButtonContainer Button:hover {
    background: rgb(205,32,38);
}

.empty .BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-content {
    opacity: 0.5;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-content h1 {
    font-size: 14px;
    font-weight: 800; 
    margin-bottom: 5px;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-content p {
    font-size: 14px;
    margin: 0px;
}

.createIdeaButtonBuildDeck {
    width: 100%;
    margin-top: 30px;
    background: rgb(250,115,85) !important;
    border: none !important;
}

.customTheme .createIdeaButtonBuildDeck {
    background: rgb(205,32,38) !important;
}

.createIdeaButtonBuildDeck:hover {
    background: black;
    border: none;
}


@media all and (max-width: 900px) {

    .BlackboxTool-content {
        min-height: 100vh;
    }

    .BlackboxTool-content.menuInActive {
        width: 100%;
        background: rgb(34,34,42);
        display: block;
    }
    .BlackboxTool-content.menuActive {
        display: none;
    }

    .BlackboxTool-content-body {
        padding-top: 20vh;
    }

    .PageInfo-card-modal-readMoreContentSidePannelContainer {
        width: 100%;
    }

    .PageInfo-card-modal-readMoreContentSidePannelContainer-closeButtonContainer {
        position: absolute;
        top: 10px;
        right: 20px;
    }

    .BuildDeck-card-modal-readMoreContentSidePannelContainer {
        width: 100%;
    }

    .BuildDeck-card-modal-readMoreContentSidePannelContainer-closeButtonContainer {
        position: absolute;
        top: 10px;
        right: 20px;
    }

}