.BlackboxExperimentProposals {
    width: 100%;
}


.BlackboxExperimentProposals-titleRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }

.BlackboxExperimentProposals-titleRow h1 {
    font-size: 28px;
    width: 70%;
    padding-left: 3%;
    box-sizing: border-box;
  }

.BlackboxExperimentProposals-titleRow-filterButton {
    all: unset;
    cursor: pointer;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background:rgba(41,41,51);
    border-radius: 5px;
    margin-left: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
}

.BlackboxExperimentProposals-titleRow-shareButton {
    all: unset;
    cursor: pointer;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background:rgba(41,41,51);
    border-radius: 5px;
    margin-left: 2%;
    width: 10%;
    height: 40px;
}

.BlackboxExperimentProposals-titleRow-filterButton:hover {
    background: rgba(0,0,0,0.5);
}

.BlackboxExperimentProposals-grid {
    width: 100%;
}

.BlackboxExperimentProposals-grid-tile {
    width: 95%;
    margin: 20px;
    background: rgba(41, 41, 51, 0.9);  /* semi-transparent background */
    backdrop-filter: blur(10px);  /* blur the background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 1);  /* dark border */
    border-radius: 10px 20px;
    box-sizing: border-box;
    padding-bottom: 10px;
}

.BlackboxExperimentProposals-grid-tile-cardInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 50px;
    margin: 0px auto;
    border-bottom: 1px solid black;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
}

.BlackboxExperimentProposals-grid-tile-cardInfo-creator {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.BlackboxExperimentProposals-grid-tile-cardInfo-creator-name {
    height: 100%;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.BlackboxExperimentProposals-grid-tile-cardInfo-creator-avatar {
    width: 30px;
    height: 30px;
    border-radius: 90%;
    border: 1px solid white;
    margin-right: 10px;
}

.BlackboxExperimentProposals-grid-tile-title , .BlackboxExperimentProposals-grid-tile-solution , .BlackboxExperimentProposals-grid-tile-votingButtons, .BlackboxExperimentProposals-grid-tile-score , .BlackboxExperimentProposals-grid-tile-arguments, .BlackboxExperimentProposals-grid-tile-details {
    padding-left: 20px;
    padding-right: 20px;
}

.BlackboxExperimentProposals-grid-tile-image {
    padding: 20px;
    padding-bottom: 10px;
}

.BlackboxExperimentProposals-grid-tile-title h4 {
    font-size: 18px;
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 30px;
}

.BlackboxExperimentProposals-grid-tile-title p {
    width: "100%";
}

.BlackboxExperimentProposals-grid-tile-title p button {
    all: unset;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
}

.BlackboxExperimentProposals-grid-tile-details p {
    width: "100%";
}

.BlackboxExperimentProposals-grid-tile-details p button {
    all: unset;
    cursor: pointer;
    width: 100%;
    text-align: right;
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
}

.BlackboxExperimentProposals-grid-tile-image img {
    width: 100%;
}

.BlackboxExperimentProposals-grid-tile-votingButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.BlackboxExperimentProposals-grid-tile-votingButtons button {
    all: unset;
    cursor: pointer;
    width: 48%;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background:rgba(0,0,0,0.5);
}

.BlackboxExperimentProposals-grid-tile-votingButtons button.inactive {
    background:rgba(0,0,0,0.1);
    opacity: 0.5;
}



.BlackboxExperimentProposals-grid-tile-votingButtons button:hover {
    background:rgba(0,0,0,0.9);
}

.BlackboxExperimentProposals-grid-tile-score {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;
    margin-top: 30px;
}

.BlackboxExperimentProposals-grid-tile-score.inactive {
    display: none;
}

.BlackboxExperimentProposals-grid-tile-arguments {
    margin-top: 10px;
}

.BlackboxExperimentProposals-grid-tile-arguments-unfoldButton {
    all: unset;
    cursor: pointer;
    width: 100%;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    background:rgba(0,0,0,0);
}

.BlackboxExperimentProposals-grid-tile-arguments button:hover {
    background:rgba(0,0,0,0.9);
    font-weight: bold;
}

.BlackboxExperimentProposals-grid-tile-arguments-pro-argument, .BlackboxExperimentProposals-grid-tile-arguments-con-argument  {
    display:flex;
    flex-direction: row;
    margin: 10px auto;
}

.BlackboxExperimentProposals-grid-tile-arguments-pro-argument-icon , 
.BlackboxExperimentProposals-grid-tile-arguments-con-argument-icon {
    margin-right: 10px;
}

.BlackboxExperimentProposals-grid-tile-arguments-shareButton {
    all: unset;
    cursor: pointer;
    width: 100%;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    background:rgba(0,0,0,0.5);
    margin-top: 20px;
}

.BlackboxExperimentProposals-shareExperimentsModal-header,
.BlackboxExperimentProposals-shareExperimentsModal-body, 
.BlackboxExperimentProposals-shareExperimentsModal-footer  {
    background: rgb(34,34,42);
    color: white;
}

.BlackboxExperimentProposals-shareExperimentsModal-body-inputLabel {
    font-size: 12px;
    font-weight: bold;
}

.BlackboxExperimentProposals-shareExperimentsModal-header button.close {
    color: #fff !important;
    fill: #fff !important;
    opacity: 1 !important;
}

.BlackboxExperimentProposals-shareExperimentsModal-body-inputField {
    background-color: rgba(0,0,0,0.5) !important;
    color: white !important;
    border: none !important;
    margin-bottom: 10px !important;
    height: auto !important;
}

.BlackboxExperimentProposals-shareExperimentsModal-footer-submitButton {
    background-color: rgba(0,0,0,0.5) !important;
    color: white !important;
    border-color: black !important;
}

.BlackboxExperimentProposals-shareExperimentsModal-footer-submitButton:hover {
    background-color: rgba(0,0,0,0.9) !important;
}


/* Modal settings*/
.randomExperimentGeneratorButton {
    all: unset;
    cursor: pointer;
    padding: 5px 10px;
    background: black;
    color: white;
    margin-left: 50px;
    font-size: 12px;
    position: absolute;
    right: 50px;
}

.randomExperimentGeneratorButtonIcon {
    margin-right: 10px;
}
