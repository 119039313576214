/* Mobile phone --------------------------------------*/

.WhoWeWorkFor {
    background: white;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    letter-spacing: 2px;
}

.WhoWeWorkFor h1 {
    font-size: 1.3em;
    font-weight: bolder;
    margin-bottom: 20px;
    font-family: 'Poppins','Montserrat', sans-serif;
    color: black;
}

.WhoWeWorkFor p {
    width: 80%;
    margin: 0px auto;
    line-height: 20px;
    font-size: 15px;
    letter-spacing: 3px;
    color: black;
}

.WhoWeWorkFor a {
    all: unset;
    cursor: pointer;
    border: none;
    font-size: 14px;
    font-weight: bolder;
    border-radius: 20px;
    padding: 5px 20px;
    letter-spacing: 2px;
    color: white;
    text-align: left;
    width: auto;
    height: auto;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50px;
}

.WhoWeWorkFor a:hover {
    all: unset;
    cursor: pointer;
    border: none;
    font-size: 14px;
    font-weight: bolder;
    border-radius: 20px;
    padding: 5px 20px;
    letter-spacing: 2px;
    color: white;
    text-align: left;
    width: auto;
    height: auto;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50px;
}

/* Desktop ---------------------------------------------------------------------------*/
@media all and (min-width: 1350px) {
    .WhoWeWorkFor p {
        width: 60%;
        line-height: 30px;
        font-size: 1vw;
        letter-spacing: 0.17vw;
    }

    .WhoWeWorkFor .roundScrollButton {
        font-size: 1vw;
    }
}