.BlackboxCIKnowledgeBase {
    width: 100%;
    margin-top: 10vh;
}

.BlackboxCIKnowledgeBase-titleRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .BlackboxCIKnowledgeBase-titleRow-title {
    font-size: 28px;
    width: 100%;
    box-sizing: border-box;
  }

.BlackboxCIKnowledgeBase-rowForFilterAndSortButtons {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
}

.BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-filterButtons {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row !important;
}

.BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-filterButtons .dropdownFilters {
    margin-right: 10px;
}

.dropdownFilters button {
    background-color: black;
    border: none;
}

.dropdownFilters button:hover {
    background-color: rgb(250,115,85);
    border: none;
}

.customTheme .dropdownFilters button {
    background: rgb(205,32,38);
}

.dropdownFilters.active-filter button {
    background-color: rgb(250,115,85);
    border: none;
}

.customTheme .dropdownFilters.active-filter button {
    background: rgb(205,32,38);
}

.BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-sortButtons {
    width: 20%;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-end;
}

.sortBySalesButton {
    background: rgba(0, 0, 0, 0);
    border: none;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.sortBySalesButton.sortActive {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
}

.sortBySalesButton div {
    margin-right: 20px;
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card {
    width: 95%;
    margin: 20px auto;
    background: black;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;
}

.customTheme .BlackboxCIKnowledgeBase-rowForCollectionOfCards-card {
    background: rgba(46, 46, 56, 1);
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-imageContainer img {
    width: 100%;
}

img.cheezItImage {
    width: 100% !important;
    background: white;
    padding: 70px;
    box-sizing: border-box;
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    width: 70%;
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-titleContainer h5 {
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 10px;
    width: 30%;
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button {
    background: rgb(41,41,51);
    border: none;
    margin: 10px auto;
    width: 90%;
    font-size: 12px;
}

.customTheme .BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button {
    background: rgb(0,0,0,0.8);
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button:hover {
    background: rgb(250,115,85);
    border: none;
}

.customTheme .BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button:hover {
    background: rgb(205,32,38);
}

.BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button.addToDeckButton {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 15px;
    right: 25px;
    width: 30px;
    height: 30px;
    font-size: 25px;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal {
    position: fixed;
    z-index: 1000;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal-background {
    position: fixed;
    z-index: 1001;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal-readMoreContentSidePannelContainer {
    position: fixed;
    z-index: 1002;
    top: 0px;
    right: 0px;
    padding: 20px;
    width: 32%;
    height: 100vh;
    background: rgb(41,41,51);
    overflow-y: scroll;
    box-sizing: border-box;
    box-shadow: rgba(30,30,30) 0px 0px 10px 0px;
}

.BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal-readMoreContentSidePannelContainer-closeButtonContainer {
    position: absolute;
    top: 0px;
    right: 40px;
    font-size: 25px;
    cursor: pointer;
    padding: 20px;
    z-index: 1002;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content {
    width: 94%;
    margin: 0 auto;
    margin-top: 50px;
}

.BuildDeck-card-modal-readMoreContentSidePannelContainer-content-title {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px;
}

/* general knowledge base item styling of the content in the side-panel, for country, persona, and other specific knowledge base item styling see dedicated css file */

.BlackboxCIKnowledgeBaseItem {
    margin-top: 50px;
}

.BlackboxCIKnowledgeBaseItem-title {
    width: 94%;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 800;
    padding: 0px;
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 20px auto;
    margin-top: 20px;
    width: 94%;
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img.BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn-image {
    width: 100%;
    margin: 0 auto;
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-title {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    width: 90%;
    text-align: left;
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button {
    background: black;
    border: none;
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin: 10px auto;
    width: 80%;
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button.activeButton {
    background: rgb(250,115,85);
    border: none;
}

.customTheme .BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button.activeButton {
    background: rgb(205,32,38);
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button:hover {
    background: rgb(250,115,85);
    border: none;
}

.customTheme .BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button:hover {
    background: rgb(205,32,38);
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    width: 90%;
    text-align: left;
    padding: 5px 10px;
    background-color: rgba(0,0,0, 0.8);
    border-radius: 5px;
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-title {
    color: rgba(255, 255, 255, 0.8);
}

.BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-data {
    font-size: 18px;
}

.BlackboxCIKnowledgeBaseItem-barChartCard {
    width: 94%;
    margin: 15px auto;
    background-color: rgba(0,0,0, 0.8);
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
}

.BlackboxCIKnowledgeBaseItem-barChartCard-title {
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    margin-bottom: 10px;
}

.BlackboxCIKnowledgeBaseItem-barChartCard-chart {
    width: 100%;
    margin: 15px auto;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.BlackboxCIKnowledgeBaseItem-FlavourMap {
    width: 94%;
    margin: 15px auto;
    background-color: rgba(0,0,0, 0.8);
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
}

.BlackboxCIKnowledgeBaseItem-FlavourMap img {
    width: 100%;
    margin: 0 auto;
}

@media all and (max-width: 1600px) {
    .BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-titleContainer h5 {
        color: white;
        font-size: 12px;
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: left;
    }


    
    .BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer Button {
        font-size: 12px;
    }
}



@media all and (max-width: 900px) {

    .BlackboxCIKnowledgeBase {
        width: 90%;
        margin: 10px auto;
    }

    .BlackboxCIKnowledgeBase-MekkoGraph {
        display: none;
        width: 300px !important;
        margin: 0 auto;
    }
    
    .BlackboxCIKnowledgeBase-rowForFilterAndSortButtons {
        display: flex;
        flex-direction: column;
        margin: 10px auto;
        width: 100%;
    }

    .BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-filterButtons {
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 10px auto;
        width: 100% !important;
    }

    .BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-filterButtons .dropdownFilters {
        margin: 10px auto;
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        width: 100% !important;
    }

    .BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-filterButtons .dropdownFilter .dropdownItem {
        width: 100% !important;
    }
      

    .BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-sortButtons {
        width: 100% !important;
        margin: 10px auto;
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
    }

    .BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal-readMoreContentSidePannelContainer {
        width: 100%;
    }
    
}


