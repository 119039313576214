.BlackboxExperimentSetupDetails {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .header h1 {
    font-size: 24px;
  }

  .header h1 span {
    font-size: 24px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .tiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    width: 95%;
    position: relative;
  }
  
  .tile {
    padding: 1rem;
    border: none;
    background: rgb(41,41,51);
    border-radius: 5px;
    position: relative;
  }

  .content.textarea {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    margin-bottom: 2rem;
    padding:10px;
    box-sizing: border-box;
  }
  
  .tile-title {
    display: flex;
    align-items: center;
    height: 30px;
    margin-bottom: 15px;
  }
  
  .icon {
    font-size: 24px;
    margin-right: 0.5rem;
  }
  
  .content {
    min-height: 100px;
  }
  
  .edit-button-general {
    all: unset;
    padding: 0.5rem 0.5rem;
    padding-right:0.2rem;
    margin-left: 1rem;
    border-radius: 5px;
    background-color: #292933;
    color: white;
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit-button-tile {
    all: unset;
    padding: 0.5rem 0.5rem;
    padding-right:0.2rem;
    margin-left: 1rem;
    border-radius: 5px;
    background-color: #292933;
    color: white;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit-button-icon {
    margin-right: 0.5rem;
  }
