/* Mobile phone --------------------------------------*/

.IntroductionPage {
    background: white;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    letter-spacing: 2px;
}

.IntroductionPage p {
    width: 80%;
    margin: 0px auto;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 3px;
}

.IntroductionPage .roundScrollButton {
    all: unset;
    cursor: pointer;
    border: 2px solid black;
    font-size: 14px;
    letter-spacing: 2px;
    color: black;
    text-align: left;
    border-radius: 90%;
    width: 50px;
    height: 50px;
    background-color: rgba(0,0,0,0);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50px;
}

.IntroductionPage .roundScrollButton {
    left: 0px !important;
}

/* Desktop ---------------------------------------------------------------------------*/
@media all and (min-width: 1350px) {
    .IntroductionPage p {
        width: 60%;
        line-height: 30px;
        font-size: 1vw;
        letter-spacing: 0.17vw;
    }

    .IntroductionPage .roundScrollButton {
        font-size: 1vw;
    }
}