/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {
    .MobileExperimentPage {
        background-image: radial-gradient( circle at 50% 40% , #F1B39C, #D79178) !important;
        width: 100%;
        height: auto;
        display: block;
        padding-top: 100px;
        padding-bottom: 100px;
        font-family: 'Montserrat', sans-serif;
    }

    .MobileExperimentPage-introText {
        width: 80%;
        color: white;
        margin: 0px auto;
        margin-top: 100px;
        text-align: center;
    }
    
    .MobileExperimentPage-introText h1 {
        font-size: 22px;
        letter-spacing: 2px;
        margin-bottom: 20px;
        font-family: 'Montserrat', sans-serif;
    }
    
    .MobileExperimentPage-introText p {
        letter-spacing: 2px;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
        font-family: 'calibri';
    }

    .MobileExperimentPage-experimentCards-card {
        width: 90%;
        height: 100%;
        margin: 20px;
        background-color: rgb(248,248,248);
        border-radius: 5px;
        display: flex;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
    }

    .MobileExperimentPage-experimentCards-card img {
        width: 86%;
        margin: 20px auto;
    }

    .MobileExperimentPage-experimentCards-card h1 {
        font-size: 20px;
        letter-spacing: 3px;
        width: 86%;
        margin: 20px auto;
        margin-bottom: 20px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }
    
    .MobileExperimentPage-experimentCards-card h2 {
        font-size: 32px;
        letter-spacing: 3px;
        width: 86%;
        margin: 20px auto;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .MobileExperimentPage-experimentCards-card p {
        font-size: 14px;
        letter-spacing: 2px;
        width: 86%;
        margin: 30px auto;
        margin-top: 0px;
        text-align: center;
    }
    
    .MobileExperimentPage-experimentCards-card h2 sup, .MobileExperimentPage-experimentCards-card h2 sub {
        font-size: 12px;
        letter-spacing: 2px;
    }
    
    .MobileExperimentPage-expermentCards-card ul {
        letter-spacing: 2px;
        font-size: 12px;
        width: 86%;
        margin: 20px auto;
        list-style-type: '✓';
        padding-right: 20px;
    }
    
    .MobileExperimentPage-experimentCards-card ul li {
        padding-left: 10px;
    }
    
    .MobileExperimentPage-experimentCards-card div.subscriptionOptions {
        all:unset;
        display: flex;
        flex-wrap: nowrap;
        width: 86%;
        margin: 10px auto;
        height: auto;
    }
    
    .MobileExperimentPage-experimentCards-card div.subscriptionOptions div.subscriptionOptions-button {
        all:unset;
        cursor: pointer;
        text-align: center;
        background-color: transparent;
        border: 1px solid black;
        color: black;
        width:100%;
        padding: 5px;
        font-size: 14px;
    }

    .MobileExperimentPage-experimentCards-card div.subscriptionOptions div.subscriptionOptions-button:hover {
        background-color: #F1B39C;
    }
    
    .MobileExperimentPage-experimentCards-card .subscriptionCTA {
        all:unset;
        display: flex;
        width: 86%;
        margin: 0px auto;
    }
    
    .MobileExperimentPage-experimentCards-card .subscriptionCTA-button {
        all:unset;
        cursor: pointer;
        width: 100%;
        text-align: center;
        background-color: black;
        color: white;
        padding: 10px;
        margin-bottom: 20px;
        font-size: 14px;
        text-transform: uppercase;
    }
    
    .MobileExperimentPage-experimentCards-card .subscriptionCTA-button:hover {
        background-color: #D79178;
    }

    .MobileExperimentPage-progress {
        position: relative;
        margin-top:30px;
        margin-bottom: 0px;
      }
    
      p.MobileExperimentPage-card-text-swipe {
        margin-top: 40px;
        font-size: 12px;
        font-style: italic;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 10px;
        color: white;
      }
    
      div.MobileExperimentPage-progressDots{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
      div.MobileExperimentPage-progressDots-dotActive {
        border-radius: 100%;
        width: 10px;
        height: 10px;
        margin: 5px;
        background-color: white;
        border:1px solid white;
      }
      div.MobileExperimentPage-progressDots-dotInActive {
        border-radius: 100%;
        width: 10px;
        height: 10px;
        margin: 5px;
        background-color: rgba(0, 0, 0, 0);
        border:1px solid white;
      }

      .swipeIndicator {
        width: 20px;
        text-align: center;
        color: white;
        font-size: 16px;
      }

      .swipeText {
        width: 80%;
        margin: 10px auto;
        text-align: center;
        color: white;
        font-size: 14px;
        font-style: italic;
      }
}

/* Laptop ---------------------------------------------------------------------------*/
@media all and (min-width: 1351px) {
    .MobileExperimentPage {
        display: none;
    }
}