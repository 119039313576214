/* Mobile ---------------------------------------------------------------------------*/

.BlackboxRegisterPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
    background:#292933;
    color: white;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .BlackboxRegisterPage .FlyingCheezItInBackground {
    position: absolute;
    width: 200px;
    z-index: 0;
  }
  
  .BlackboxRegisterPage .FlyingCheezItInBackground.CheezIt1 {
    top: 50px;
    left: 10%;
  }
  
  .BlackboxRegisterPage .FlyingCheezItInBackground.CheezIt2 {
    top: 50%;
    left: 20%;
    width: 250px;
  }
  
  .BlackboxRegisterPage .FlyingCheezItInBackground.CheezIt3 {
    top: 20%;
    right: 15%;
    width: 250px;
  }
  
  .BlackboxRegisterPage-registerCard {
    background-color: #111;
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    box-shadow: none;
  }

  .BlackboxRegisterPage-registerCard-registeredButEmailNotYerVerrified {
    background-color: #111;
    text-align: center;
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 0px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .BlackboxRegisterPage-registerCard-registeredButEmailNotYerVerrified p {
    padding-left: 20px;
    padding-right: 20px;
  }

.BlackboxRegisterPage-registerCard-backgroundTop {
    position: absolute;
    display: block;
    width: 100%;
  }
  
  .BlackboxRegisterPage-registerCard-header {
    width: 80%;
    margin: 40px auto;
    position: relative;
    top: 20%;
    width: 90%;
    text-align: left;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .BlackboxRegisterPage-logo {
    width: 60%;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  
  .BlackboxRegisterPage-registerCard-header h1 {
    margin-bottom: 10px;
    font-size: 28px;
  }
  
  .BlackboxRegisterPage-registerCard-header p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .BlackboxRegisterPage-registerCard-header div.smallDevider {
    height: 3px;
    width: 25%;
    background: white;
    margin-top: 20px;
  }
  
  .BlackboxRegisterPage-registerCard-body {
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 20%;
  }
  
  
  .BlackboxRegisterPage-registerCard-body form{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 90%;
  }

  .BlackboxRegisterPage-registerCard-body-inputArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .BlackboxRegisterPage-registerCard-body label {
    text-align: left;
    margin-bottom: 7px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .BlackboxRegisterPage-registerCard-body input {
    padding: 10px 0px;
    margin-bottom: 15px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 5px;
    background-color: #292933;
    background-color: white;
    color: white;
    color:black;
    font-size: 14px;
    padding-right: 70px;
    padding-left: 10px;
  }
  
  .BlackboxRegisterPage-registerCard-body input:focus{
    background-color: #292933;
    background-color: white;
    padding-left: 5px;
    outline: none;
    padding-left: 10px;
  }

  .BlackboxRegisterPage-registerCard-body-inputArea-visibilityIcon {
    position: absolute;
    right: 20px;
    top: 55%;
    cursor: pointer;
    padding: 5px;
    color: rgba(200,200,200);
    color: rgba(50,50,50);
  }
  
  .BlackboxRegisterPage-registerCard-body button.submitButton {
    margin: 10px auto;
    background-color: #292933;
    color: white;
    border-radius: 5px;
    border: transparent solid 1px;
    width: 100%;
    margin-top: 50px;
    font-size: 16px;
    text-justify: center;
    padding:10px;
  }

  .BlackboxRegisterPage-registerCard-body button.submitButton:hover {
    font-weight: 600;
  }
  
  .BlackboxRegisterPage-registerCard-footer{
    width: 100%;
    font-size: 12px;
  }
  
  .BlackboxRegisterPage-registerCard-footer a {
    color: white;
    font-weight: bold;
  }
  
  
  
  /* Tablet ---------------------------------------------------------------------------*/
  @media all and (min-width: 800px) {
    .BlackboxRegisterPage-registerCard {
      width: 50%;
      height: auto;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-radius: 5px;
    }
    .BlackboxRegisterPage-registerCard-registeredButEmailNotYerVerrified {
      width: 50%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
      border-radius: 5px;
    }
  }
  /* Laptop ---------------------------------------------------------------------% */
  
  @media all and (min-width: 1000px) {
  
    .BlackboxRegisterPage-registerCard {
      width: 25%;
    }

    .BlackboxRegisterPage-registerCard-registeredButEmailNotYerVerrified {
      width: 25%;
    }
  
  }