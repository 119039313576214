/* BlackboxFeatures.css */
.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  color: white;
  margin: 50px auto;
  width: 90%;
  box-sizing: border-box;
}

.feature-card {
  padding: 20px;
  background: radial-gradient(circle at 0% 0%, rgba(52,53,57,0.5), rgba(22,25,28,0.5));
  box-shadow: black 0px 0px 10px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  transition: all 0.3s ease;
  text-align: center;
}

.feature-icon {
  color: rgba(248, 189, 167, 1);
  font-size: 2rem;
  margin: 20px auto;
}

.feature-card.active {
  transform: scale(1.05);
  border-color: rgba(255, 255, 255, 0.5);
}

.feature-title {
  margin-bottom: 10px;
}

.feature-description {
  color: rgba(248, 189, 167, 1);
  display: none;
}

.feature-card.active .feature-description {
  display: block;
}

.feature-card-image {
  width: 100%;
  opacity: 0.9;
  margin-bottom: 20px;
}