.BlackboxHomePage {
    width: 90%;
    margin-top: 10vh;
    height: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BlackboxHomePage h1 {
    margin-bottom: 30px;
}

.BlackboxHomePage Form {
    position: relative;
    width: 100%;
}

.BlackboxHomePage-form-questionInputField {
    width: 100%;
    font-size: 20px;
    background: rgba(0,0,0,0);
    border: solid 1px white;
    border-radius: 10px;
    color: white;
    resize: none;
    padding:20px;
    padding-right: 50px;
}

.BlackboxHomePage-form-questionSubmitButton {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 20px;	
}


.BlackboxHomePage-inspiration {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
}

.BlackboxHomePage-inspiration-titleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.BlackboxHomePage-inspiration-titleContainer button {
    position: absolute;
    right: 0;
    bottom: 40px;
    margin-right: 20px;
    background: rgba(0,0,0,0);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.BlackboxHomePage-inspiration-mobile {
    display: none;
}

.BlackboxHomePage-inspiration-title {
    margin-top: 50px;
    font-size: 16px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid rgb(240,240,240);
    padding-bottom: 15px;
    margin-bottom: 20px;
    padding-left: 20px;
    box-sizing: border-box;
}

.BlackboxHomePage-inspiration-title-icon {
    margin-right: 8px;
}

.BlackboxHomePage-inspiration-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.BlackboxHomePage-inspiration-cards.mobile {
    display: none;
}

.BlackboxHomePage-inspiration-cards-card {
    width: 32%;
    height: 170px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgba(0,0,0,0.3);
    color: white;
    border-radius: 10px;
    cursor: pointer;
    text-align: left;
}

.BlackboxHomePage-inspiration-cards-card h5 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 10px;
}

.BlackboxHomePage-inspiration-cards-card p {
    font-size: 12px;
    letter-spacing: 2px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-top: 0px;
}

@media (max-width: 900px) {

    .BlackboxHomePage {
        margin-top: 0vh;
    }
    .BlackboxHomePage-inspiration-cards {
        display: none;
    }

    .BlackboxHomePage-inspiration-cards.mobile {
        display: flex;
        flex-direction: column;
    }

    .BlackboxHomePage-inspiration-cards-card {
        width: 100%;
    }

    .BlackboxHomePage-form-questionInputField {
        width: 100%;
    }

    .BlackboxHomePage .swiper-pagination-bullet {
        position: relative;
        top: 15px;
      }
}