/* For general styling (non-specific to the country) see the BlackboxCIKnowledgeBase.css file */

.BlackboxCIKnowledgeBaseItem-storeTypeRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
    width: 94%;
}

.BlackboxCIKnowledgeBaseItem-storeTypeRow-storeTypeCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 16%;
    background: rgba(0,0,0,0.8);
    border-radius: 5px;
}

.BlackboxCIKnowledgeBaseItem-storeTypeRow-storeTypeCard.inactive {
    color: rgba(255,255,255,0.2);
}

.BlackboxCIKnowledgeBaseItem-storeTypeRow-storeTypeCard .retailerIcon {
    margin-top: 20px;
    margin-bottom: 8px;
}

.BlackboxCIKnowledgeBaseItem-storeTypeRow-storeTypeCard div {
    margin-bottom: 12px;
    font-size: 12px;
}

.BlackboxCIKnowledgeBaseItem-infoCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 94%;
    margin: 10px auto;
    background: rgba(0,0,0,0.8);
    border-radius: 5px;
    padding: 20px;
}

.BlackboxCIKnowledgeBaseItem-infoCard .BlackboxCIKnowledgeBaseItem-infoCard-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: rgba(255,255,255,0.8);
}

.BlackboxCIKnowledgeBaseItem-infoCard .BlackboxCIKnowledgeBaseItem-infoCard-content {
    font-size: 14px;
    margin-bottom: 10px;
}

.BlackboxCIKnowledgeBaseItem-infoCard-content.motto {
    text-align: center;
    width: 80%;
    margin: 10px auto;
    text-emphasis: italic;
}
