/* Laptop ---------------------------------------*/

.BlackboxMainWebsiteDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(248,248,248);
    color: black;
}


.BlackboxMainWebsiteDescription-textHolder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    order:1;
}

.BlackboxMainWebsiteDescription-textHolder.switch{
    order:2;
}

.BlackboxMainWebsiteDescription-imageHolder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    order:2;
}

.BlackboxMainWebsiteDescription-imageHolder.switch{
    order:1;
}

.BlackboxMainWebsiteDescription-imageHolder-image{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E9A992;
    border-radius: 5px;
    box-shadow: 20px 20px rgb(252, 196,175);
    width: 70%;
    height: auto;
}

.BlackboxMainWebsiteDescription-textHolder-text{
    display: flex;
    flex-wrap:wrap;
    justify-content: left;
    align-content: center;
    text-align: left;
    width: 70%;
    height: 100%;
}

.BlackboxMainWebsiteDescription-textHolder-text h1 {
    width: 100%;
    margin-bottom: 10px;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Poppins', 'Montserrat', sans-serif;
}

.BlackboxMainWebsiteDescription-textHolder-text p {
    width: 100%;
    margin-top: 10px;
    letter-spacing: 0.17vw;
    font-size: 1vw;
}


.BlackboxLearnMoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 20px;
    border-radius: 20px;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    color: white;
    font-size: 1.1vw;
    font-weight: bolder;
    letter-spacing: 0.2vw;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
}

.BlackboxLearnMoreButton:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 20px;
    border-radius: 20px;
    background: #E9A992;
    color: white;
    font-size: 1.1vw;
    font-weight: bolder;
    letter-spacing: 0.2vw;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
}


/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {
    .BlackboxMainWebsiteDescription {
        flex-wrap: wrap;
        height: 100vh;
    }

    .BlackboxMainWebsiteDescription.switch {
        background-color: rgb(248,248,248);
    }
    
    .BlackboxMainWebsiteDescription-textHolder{
        height: auto;
        width: 100%;
        order:2;
    }
    .BlackboxMainWebsiteDescription-imageHolder{
        align-items:flex-end;
        width: 100%;
        height: 50%;
        order: 1;
    }

    .BlackboxMainWebsiteDescription-imageHolder-image{
        box-shadow: 10px 10px rgb(252, 196,175);
        width: 70%;
        height: auto;
    }

    .BlackboxMainWebsiteDescription-textHolder-text {
        width: 80%;
        position: relative;
    }

    .BlackboxMainWebsiteDescription-textHolder-text h1 {
        font-size: 22px;
        text-align: center;
        margin-top: 30px;
        width: 90%;
        margin: 10px auto;
        margin-top: 40px;
    }

    .BlackboxMainWebsiteDescription-textHolder-text p {
        width: 80%;
        margin: 10px auto;
        margin-bottom: 30px;
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 3px;
        text-align: center;
    }

    .BlackboxLearnMoreButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        margin-top: 0px;
        padding: 5px 20px;
        border-radius: 20px;
        background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
        color: white;
        font-size: 14px;
        font-weight: bolder;
        letter-spacing: 2px;
        cursor: pointer;
        transition: 0.3s;
        text-decoration: none;
    }
    
    .BlackboxLearnMoreButton:hover {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 5px 20px;
        border-radius: 20px;
        background: #E9A992;
        color: white;
        font-size: 1.1vw;
        font-weight: bolder;
        letter-spacing: 0.2vw;
        cursor: pointer;
        transition: 0.3s;
        text-decoration: none;
    }
    
}
