/* LandingPage.css */
.landing-page {
    font-family: 'Montserrat', sans-serif;
    color: white;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    backdrop-filter: blur(10px);
    width: 100%;
    margin: 0px auto;
    box-sizing: border-box;
  }

    .BlackboxLandingPage-navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 8vh;
        width: 100%;
        position: absolute;
        top: 30px;
        left: 0;
        z-index: 1000;
    }



  .BlackboxLandingPage-navbar-logo {
    width: 10%;
    object-fit: cover;
    margin-right: 20px;
    position: relative;
    top: 5px;
    left: 60px;
  }

  .BlackboxLandingPage-navbar-logo img {
    width: 100%;
  }

  .BlackboxLandingPage-navbar-links {
    width: 90%;
    padding-right: 3%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    box-sizing: border-box;
  }

  a.BlackboxLandingPage-navbar-links-loginButton {
    all: unset;
    padding: 5px 20px;
    font-size: 14px;
    border: 1px solid white;
    border-radius: 0px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-right: 20px;
  }

  a.BlackboxLandingPage-navbar-links-howtoflyButton {
    all: unset;
    padding: 5px 20px;
    font-size: 14px;
    border: none;
    border-radius: 0px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-right: 20px;
  }
  
  .introduction {
    margin-bottom: 50px;
    padding-bottom: 20px;
    position: relative;
  }

  .introduction-topHalf-heroImage {
    width: 100%;
    height: 100vh !important;
    position: absolute;
    top: 0;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .introduction-topHalf-heroImage img {
    width: auto;
    height: 70%;
    position: relative;
    top: 60px;
  }

  .introduction-topHalf-overlayText {
    z-index: 100;
    position:relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  
  .introduction-topHalf-overlayText  h1 {
    color: rgba(255,255,255,0.9);
    font-size: 6rem;
    font-weight: bolder;
    position: relative;
    top: 30px;
  }
  
  .introduction-topHalf-overlayText  p {
    margin-bottom: 20px;
    width: 60%;
    text-align: center;
    position: relative;
    top: 15px;
    color: rgba(255,255,255,0.9);
    letter-spacing: 2px;
    font-size: 14px;
  }

  .introduction-topHalf-overlayText-button {
    all: unset;
    padding: 10px 20px;
    border: 1px solid rgba(252, 196,175,1);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
  }

  .introduction-topHalf-overlayText-button:hover {
    background: radial-gradient(circle at 0% 0%, rgb(222,151,122), rgba(252, 196,175));
  }

  .overview-section {
    margin-top: 50px;
    background-color: rgb(245,245,245);
    width: 100%;
    color: black;
    position: relative;
  }

  .overview-section p {
    font-size: 16px;
  }

  .overview-section h1 {
    font-size: 28px;
    font-family: 'Poppins','Montserrat', sans-serif;
    font-weight: bolder;
  }

  .overview-section p {
    font-size: 14px;
  }

  .features-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 90%;
    margin: 100px auto;
  }

  .features-section-mainTitle {
    font-weight: bolder;
  }

  .features-section-mainTitle-5ways{
    font-size: 40px;
    color: rgba(255,255,255,0.8);
    font-weight: bolder;
  }
  
  .features-section-tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    background: rgb(31,31,33);
    box-sizing: border-box;
    padding: 20px;
  }
  
  .features-section-tile-number {
    font-size: 4em;
    color: rgba(255, 255,255,0.8);
  }
  
  .features-section-tile-title {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .features-section-tile-description {
    text-align: left;
    color: rgba(255,255,255,0.7);
    font-weight: 200;
    margin-bottom: 30px;
    letter-spacing: 3px;
    font-size: 14px;
  }

  .contact-section {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(245,245,245);
  }

  .contact-section-contactIcon {
    color: rgba(0,0,0,0.5);
    font-size: 3em;
    display: none;
  }

  .contact-section-contactText {
    color: black;
    font-size: 1.2vw;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 40px;
  }

  .contact-section-contactButton {
    all: unset;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;
    font-size: 12px;
    color: white;
    background: black;
  }

  .contact-section-contactButton:hover {
    background: rgb(252, 196,175);
    color: white;
  }

  .contact-section-contactButton-link {
    all: unset !important;
  }

  /* Mobile ---------------------------------------------------------------------------*/
  @media all and (max-width: 1350px) {

    .landing-page {
      max-width: 100% !important;
      box-sizing: border-box !important;
    }

    .BlackboxLandingPage-navbar-logo {
      width: 40%;
      object-fit: cover;
      margin-right: 20px;
      position: relative;
      top: 0px;
      left: 40px;
    }

    .introduction-topHalf-overlayText  h1 {
      font-size: 3rem;
      box-sizing: border-box;
    }
    .introduction-topHalf-overlayText  p {
      font-size: 0.8rem;
      margin-top: 20px;
      box-sizing: border-box;
    }

    .introduction-topHalf-overlayText  p br {
      display: none;
    }

    .introduction-topHalf-heroImage img {
      overflow: hidden;
    }

    .introduction-topHalf-overlayText-button {
      margin-top: 30px;
    }

    .overview-section img {
      box-shadow: 10px 10px rgb(252, 196,175);
      width: 70%;
      height: auto;
      margin-bottom: 50px;
    }

    .overview-section h1 {
      font-size: 18px;
    }

    .overview-section p {
      font-size: 0.8rem;
    }

    a.BlackboxLandingPage-navbar-links-howtoflyButton {
      display: none;
    }
    .features-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 80% !important;
      margin: 100px auto;
      box-sizing: border-box;
    }

    .contact-section {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgb(245,245,245);
      box-sizing: border-box;
      padding: 20px 10%;
      height: 100vh;
    }

    .contact-section p {
        text-align: center;
        color: black;
        font-size: 16px;
        letter-spacing: 2px;
    }

    .contact-section-contactText-break {
      display: none;
    }
  }