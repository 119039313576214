/* Mobile ---------------------------------------------------------------------------*/

.BlackboxLoginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background:#292933;
  color: white;
}

.BlackboxLoginPage .FlyingCheezItInBackground {
  position: absolute;
  width: 200px;
  z-index: 0;
}

.BlackboxLoginPage .FlyingCheezItInBackground.CheezIt1 {
  top: 50px;
  left: 10%;
}

.BlackboxLoginPage .FlyingCheezItInBackground.CheezIt2 {
  top: 50%;
  left: 20%;
  width: 250px;
}

.BlackboxLoginPage .FlyingCheezItInBackground.CheezIt3 {
  top: 20%;
  right: 15%;
  width: 250px;
}

.BlackboxLoginPage-loginCard {
  background-color: #111;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  box-shadow: none;
  padding-bottom: 50px;
}

.BlackboxLoginPage-logo {
  width: 70%;
  margin: 10px auto;
  position: relative;
  top: 15vh;
}

.BlackboxLoginPage-loginCard-backgroundTop {
  position: absolute;
  display: block;
  width: 100%;
}

.BlackboxLoginPage-loginCard-header {
  width: 80%;
  margin: 40px auto;
  position: relative;
  top: 20%;
  width: 90%;
  text-align: left;
  margin-left: 10%;
}

.BlackboxLoginPage-loginCard-header h1 {
  margin-bottom: 10px;
  font-size: 28px;
}

.BlackboxLoginPage-loginCard-header p {
  margin-top: 10px;
  font-size: 14px;
}

.BlackboxLoginPage-loginCard-header div.smallDevider {
  height: 3px;
  width: 25%;
  background: white;
  margin-top: 20px;
}

.BlackboxLoginPage-loginCard-body {
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 20%;
}


.BlackboxLoginPage-loginCard-body form{
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 90%;
}

.BlackboxLoginPage-loginCard-body label {
  text-align: left;
  margin-bottom: 7px;
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
}

.BlackboxLoginPage-loginCard-body input {
  padding: 10px 0px;
  margin-bottom: 15px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 15px;
  background-color: #292933;
  background-color: white;
  color: white;
  color: black;
  font-size: 14px;
}

.BlackboxLoginPage-loginCard-body input:focus{
  background-color: #292933;
  background-color: white;
  padding-left: 15px;
  outline: none;
}

.BlackboxLoginPage-loginCard-body button.submitButton {
  margin: 10px auto;
  background-color: #292933;
  color: white;
  border-radius: 5px;
  border: transparent solid 1px;
  width: 100%;
  margin-top: 50px;
  font-size: 16px;
  padding: 10px 0px;
}

.forgotPassword {
  text-align: right;
  margin-top: -5px;
  font-style: 10px;
  font-style: italic;
  cursor: pointer;
  color:white;
  font-weight: bold;
  font-size: 12px;
}

.BlackboxLoginPage-loginCard-footer{
  width: 100%;
  font-size: 12px;
}

.BlackboxLoginPage-loginCard-footer a {
  color: white;
  font-weight: bold;
}



/* Tablet ---------------------------------------------------------------------------*/
@media all and (min-width: 800px) {
  .BlackboxLoginPage-loginCard {
    width: 50%;
    height: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
  }
}
/* Laptop ---------------------------------------------------------------------% */

@media all and (min-width: 1000px) {

  .BlackboxLoginPage-loginCard {
    width: 25%;
    height: 95%;
  }

}