/* LandingPage.css */
.SevenStepPage {
    font-family: 'Montserrat', sans-serif;
    color: white;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    backdrop-filter: blur(10px);
    width: 100%;
    margin: 0px auto;
    box-sizing: border-box;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SevenStep-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 90%;
    margin: 200px auto;
  }

  .SevenStep-section-mainTitle {
    font-weight: bolder;
  }

  .SevenStep-section-mainTitle-5ways{
    font-size: 40px;
    color: rgba(255,255,255,0.8);
    font-weight: bolder;
  }
  
  .SevenStep-section-tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    background: rgb(31,31,33);
    box-sizing: border-box;
    padding: 20px;
  }
  
  .SevenStep-section-tile-number {
    font-size: 4em;
    color: rgba(255, 255,255,0.8);
  }
  
  .SevenStep-section-tile-title {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .SevenStep-section-tile-description {
    text-align: left;
    color: rgba(255,255,255,0.7);
    font-weight: 300;
    margin-bottom: 30px;
    font-family: 'calibri';
    font-size: 0.9vw;
    letter-spacing: 0.17vw;
 
  }

  /* Mobile ---------------------------------------------------------------------------*/
  @media all and (max-width: 1350px) {

    .SevenStepPage {
      display: none;
    }
  }