/* For general styling (non-specific to the country) see the BlackboxCIKnowledgeBase.css file */

.persona-card {
    width: 94%;
    border-radius: 10px;
    margin: 0 auto;
}

.persona-card .infoContainer {
    background: rgba(0,0,0,0.8);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.persona-card .info {
    font-size: 14px;
    margin: 5px 0;
}

.persona-card h5 {
    font-size: 18px;
    font-weight: 800;
}

.persona-card ul {
    padding: 0;
}

.persona-card ul li {
    padding: 0px 0;
    font-size: 14px;
    margin-left: 30px;
}

.chatbotContainer {
    width: 94%;
    margin: 0 auto;
    margin-top: 50px;
}

.journeyContainer {
    width: 94%;
    margin: 0 auto;
    margin-top: 50px;
    background: black;
    color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.journey-grid {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    width: 95%;
    margin: 0 auto;
  }

  .journeyStageImage {
    width: 100%;
  }
  
  .journey-header, .journey-row {
    display: flex;
    position: relative;
  }

  .journey-row-header-topic {
    position: absolute;
    top: 20px;
    left: -60px;
    transform: rotate(-90deg);
    background: rgb(205, 32, 38);
    color: white;
    padding: 0px 10px 10px 10px;
    font-size: 10px;
    border-radius: 5px;
  }

  .journey-row-header-topic.Stage {
    left: -25px;
    background: rgb(247,168,0);
  }

  .journey-row-header-topic.Activities {
    left: -32px;
  }

  .journey-row-header-topic.EmotionShift {
    left: -39px;
    background: rgb(247,168,0);
  }

  .journey-row-header-topic.PainPoints {
    left: -35px;
  }

  .journey-row-header-topic.CheezItTouchpoints {
    left: -55px;
    top: 40px; 
    background: rgb(247,168,0);
  }
  
  .journey-column-header, .journey-cell {
    flex: 1;
    border: 1px solid black;
    padding: 10px;
    width: 20%;
    font-size: 10px;
    background: black;
    border: 1px solid rgba(255,255,255,0.5);
    z-index: 1000;
    position: relative;
  }

  .contentOfJourneyCells:last-child {
    min-height: 100px;
  }

  .journey-column-header {
    font-size: 12px;
    text-align: center;
  }

  .emotionShiftImage {
    width: 100%;
  }

  h5.stagesTitle {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .itemInJourneyCell {
    margin-bottom: 5px;
  }
  
  /* If you want each cell to have equal width, you can set a fixed width */
  /* .journey-column-header, .journey-cell {
    width: 200px;
  } */
  