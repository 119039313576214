.ExperimentPage {
    background: linear-gradient(to right bottom, #F1B39C, #D79178);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.ExperimentPage-introText {
    width: 86%;
    color: white;
    margin-left: 10%;
    margin-top: 100px;
}

.ExperimentPage-introText h1 {
    letter-spacing: 3px;
    font-size: 1.7rem;
    font-weight: bold;
}

.ExperimentPage-introText p {
    letter-spacing: 0.17vw;
    font-size: 1vw;
}

.ExperimentPage-experimentCards {
    width: 80%;
    height: auto;
    margin: 10px auto;
    margin-bottom: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.ExperimentPage-experimentCards div {
    width: 30%;
    height: auto;
    margin: 20px;
    background-color: rgb(248,248,248);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ExperimentPage-experimentCards div img {
    width: 86%;
    margin: 20px auto;
}

.ExperimentPage-experimentCards div h1 {
    font-size: 1.3vw;
    letter-spacing: 0.2vw;
    font-weight: bold;
    width: 86%;
    margin: 20px auto;
    margin-bottom: 10px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.ExperimentPage-experimentCards div p {
    font-size: 1vw;
    letter-spacing: 0.17vw;
    width: 86%;
    height: 70px;
    margin: 20px auto;
    text-align: center;
    margin-top: 10px;
}

.ExperimentPage-experimentCards div h2 sup, .PricingPage-priceCards div h2 sub {
    font-size: 0.8vw;
    letter-spacing: 0.3vw;
}

.ExperimentPage-experimentCards div ul {
    letter-spacing: 0.2vw;
    font-size: 0.8vw;
    width: 86%;
    margin: 20px auto;
    list-style-type: '✓';
}

.ExperimentPage-experimentCards div ul li {
    padding-left: 10px;
}

.ExperimentPage-experimentCards div.subscriptionOptions {
    all:unset;
    display: flex;
    flex-wrap: nowrap;
    width: 86%;
    margin: 10px auto;
    height: auto;
}

.ExperimentPage-experimentCards div.subscriptionOptions div.subscriptionOptions-button {
    all:unset;
    cursor: pointer;
    text-align: center;
    background-color: transparent;
    border: 1px solid black;
    color: black;
    width:100%;
    padding: 5px;
    text-transform: uppercase;
}

.ExperimentPage-experimentCards div.subscriptionOptions div.subscriptionOptions-button:hover {
    background-color: #F1B39C;
}

.ExperimentPage-experimentCards .subscriptionCTA {
    all:unset;
    display: flex;
    width: 86%;
    margin: 0px auto;
}

.ExperimentPage-experimentCards .subscriptionCTA-button {
    all:unset;
    cursor: pointer;
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    padding: 10px;
    margin-bottom: 20px;
    text-transform: uppercase !important;
}

.ExperimentPage-experimentCards .subscriptionCTA-button:hover {
    background-color: #D79178;
}


/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {
    .ExperimentPage {
        display:none;
    }
}