.ChatbotPage {
    width: 100%;
    margin: 0px auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 200px 0px;
    background: white;
    padding-left: 5%;
    padding-right: 5%;
}

.ChatbotPage .ChatbotPage-content {
    width: 90%;
}

.ChatbotPage .ChatbotPage-content .ChatbotPage-title {
    font-size: 20px;
    letter-spacing: 3px;
    width: 100%;
    text-align: center;
}

.ChatbotPage .ChatbotPage-content .ChatbotPage-text {
    margin-top: 10px;
    font-size: 1vw;
    letter-spacing: 0.17vw;
    text-align: center;
}

.ChatbotPage .ChatbotPage-content .ChatbotPage-form {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    position: relative;
}

.ChatbotPage .ChatbotPage-content .ChatbotPage-form textarea {
    width: 100%;
    padding-left:20px;
    padding-right: 70px;
    margin-right: 5px;
    border-radius: 20px;
    border-color: #F1B39C;
    border-width: 1.5px;
    resize: none;
    overflow: hidden;
    box-sizing: 'border-box';
    min-height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ChatbotPage .ChatbotPage-content .ChatbotPage-form button {
    height: 40px;
    width: 40px;
    border-radius: 90%;
    position: relative;
    bottom: 1px;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    color: white;
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.ChatbotPage .ChatbotPage-answers {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ChatbotPage .ChatbotPage-answers-answerBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle at 35% 0%, rgba(54,55,59,1), rgba(16,19,22,1) 50%);
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 20px;
    text-align: left;
}

.ChatbotPage .ChatbotPage-answers-answerBox h1 {
    font-size: 14px;
    text-align: left;
    width: 100%;
}

.ChatbotPage .ChatbotPage-answers-answerBox p {
    font-size: 14px;
    width: 100%;
}

.ChatbotPage-inspiration {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
}

.ChatbotPage-inspiration-mobile {
    display: none;
}

.ChatbotPage-inspiration-title {
    margin-top: 50px;
    font-size: 16px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid rgb(240,240,240);
    padding-bottom: 15px;
    margin-bottom: 20px;
    padding-left: 20px;
    box-sizing: border-box;
}

.ChatbotPage-inspiration-title-icon {
    margin-right: 8px;
}

.ChatbotPage-inspiration-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ChatbotPage-inspiration-cards-card {
    width: 32%;
    height: 130px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgb(245,245,245);
    border-radius: 10px;
    cursor: pointer;
    text-align: left;
}

.ChatbotPage-inspiration-cards-card h5 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 10px;
}

.ChatbotPage-inspiration-cards-card p {
    font-size: 14px;
    letter-spacing: 2px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-top: 0px;
}

/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {

    .ChatbotPage {
        padding: 150px 5%;
        width: 100%;
        margin: 0px auto;
    }

    .ChatbotPage .ChatbotPage-content .ChatbotPage-title {
        letter-spacing: 1px;
        font-size: 28px;
        margin-bottom: 20px;
    }

    .ChatbotPage .ChatbotPage-content .ChatbotPage-text {
        letter-spacing: 2.5px;
        font-size: 14px;
    }

    .ChatbotPage .ChatbotPage-content .ChatbotPage-form {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .ChatbotPage .ChatbotPage-content .ChatbotPage-form textarea {
        width: 100%;
        padding-left:20px;
        padding-right: 70px;
        min-height: 60px;
        margin-top: 20px;
        margin-right: 5px;
        border-radius: 20px;
        font-size: 14px;
    }

    .ChatbotPage .ChatbotPage-answers-answerBox p {
        font-size: 12px;
        width: 100%;
    }

    .ChatbotPage-inspiration {
        display: none;
    }

    .ChatbotPage-inspiration-mobile {
        display: flex;
        flex-wrap: wrap;
    }

    .ChatbotPage-inspiration-mobile .ChatbotPage-inspiration-title {
        margin-top: 80px;
        margin-bottom: 0px;
        font-size: 14px;
    }

    .ChatbotPage-inspiration-mobile-cards {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        box-sizing: border-box;
    }

    .swiper-slide {
        width: 100%; /* Adjust this value to the desired width of each slide */
        height: 100%;
      }
    
    .ChatbotPage-inspiration-mobile-cards-card {
        width: 95%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
        background: rgb(245,245,245);
        border-radius: 10px;
        cursor: pointer;
        text-align: left;
        margin-bottom: 50px;
    }
    
    .ChatbotPage-inspiration-mobile-cards-card h5 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    
    .ChatbotPage-inspiration-mobile-cards-card p {
        font-size: 14px;
        letter-spacing: 2px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        margin-top: 0px;
    }

    .ChatbotPage-inspiration-mobile-cards .swiper-pagination-bullet {
        background-color: black;
      }
}