/* Laptop --------------------------------------------------------------*/

.NavbarWebTop{
    position: absolute;
    color: #fff;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 12vh;
    z-index: 1000;
}

.NavbarWebTop-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13%;
    height: 100%;
}

.NavbarWebTop-logo img {
    width: 70%;
    margin: 30px auto;
    margin-left: 30%;
}

.NavbarWebTop-leftLinks {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 35%;
}

.NavbarWebTop-leftLinks a {
    color: #000;
    text-decoration: none;
    font-size: 0.8rem;
    letter-spacing: 3px;
    padding: 10px 15px;
    margin: 0 1rem;
    cursor: pointer;
}



.unstyledLink {
    all: unset !important;
    cursor: pointer;
}

.NavbarWebTop-rightLinks.inactive button.black-callToActionNav {
    all: unset;
    cursor: pointer;
    font-family: 'EngraversGothic BT';
    background-color: transparent;
    border: 1px solid black;
    font-size: 0.8rem !important;
    letter-spacing: 3px;
    color: black;
    padding: 5px 15px;
    margin: 0 1rem;
}

.NavbarWebTop-rightLinks.inactive button.black-callToActionNav:hover {
   background: rgb(3,3,3);
   color: white;
}

.NavbarWebTop-rightLinks {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
    width: 50%;
    margin-right: 50px;
}

.NavbarWebTop-rightLinks-link:hover {
    font-weight: bold;
}



.NavbarWebTop-rightLinks a {
    color: #000;
    text-decoration: none;
    font-size: 0.8rem;
    letter-spacing: 3px;
    padding: 10px 15px;
    margin: 0 1rem;
    cursor: pointer;
}

.NavbarWebTop-menuToggle {
    display: none;
}

/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {
    .NavbarWebTop {
        position: fixed;
        flex-wrap: wrap;
        background-color: black;
        height: 100%;
        align-content: space-between;
        box-sizing: content-box;
    }
    .NavbarWebTop-logo {
        width: 36%;
        height: 10vh;
        color: #fff;
        justify-content: left;
        margin-left: 10%;
        margin-top: 5px;
        font-size: 1.3rem;
    }

    .NavbarWebTop-logo-black {
        opacity: 0.8;
    }

    .NavbarWebTop-logo img {
        width: 100%;
        margin: 10px auto;
        margin-left: 0%;
    }
    
    .NavbarWebTop-menuToggle {
        display:flex;
        justify-content: right;
        align-items: center;
        height: 10vh;
        width: 40%;
        margin-right:10%;
    }
    .NavbarWebTop-menuToggle .toggleBars {
        font-size: 1.5rem;
        color: #F1B39C;
        cursor: pointer;
    }

    .NavbarWebTop-leftLinks {
        width: 100%;
        height: auto;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 30px;
        display: none;
        position: relative;
        bottom: 50px;
    }

    .NavbarWebTop-leftLinks.inactive {
        display: none;
    }

    .NavbarWebTop-leftLinks a {
        font-size: 1.5rem;
        width: 100%;
        color:white;
        margin-bottom: 20px;
    }
    .NavbarWebTop-rightLinks {
        width: 100%;
        height: 80vh;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        text-align: center;
        margin: 40px auto;
    }

    .NavbarWebTop-rightLinks.inactive {
        display: none;
    }

    .NavbarWebTop-rightLinks a {
        font-size: 1rem;
        margin-bottom: 20px;
        letter-spacing: 3px;
        width: 55%;
        color:white;
    }

    .NavbarWebTop-rightLinks button.black-callToActionNav {
        all: unset;
        cursor: pointer;
        font-family: 'EngraversGothic BT';
        border: 1px solid black;
        font-size: 1rem !important;
        letter-spacing: 3px;
        padding: 5px 15px;
        margin: 0 1rem;
        background: white;
        color: black ;
        margin-top: 30px;
    }

    /* Mobile Menu inactive --------------------------*/

    .NavbarWebTop.inactive {
        position: absolute;
    }

    .NavbarWebTop.inactive {
        background-color: transparent;
        height: 10vh;
    }

    .NavbarWebTop.inactive .navbar-logo {
        color: #fff;
    }

    .NavbarWebTop-leftLinks.inactive a {
        display: none;
    }

    .NavbarWebTop-rigthLinks.inactive {
        display: none;
    }
}