.BlackboxTool-menu {
    width: 18%;
    height: 100vh;
    background: rgb(41,41,51);
    box-shadow: 10px 0 10px -5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px !important;
    left: 0px;
    z-index: 3;
}

.customTheme .BlackboxTool-menu {
    background: rgb(205,32,38);
}

.BlackboxTool-menu-header {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    margin-top: 30px;
    margin-bottom:40px;
  }

  .MenuCloseButton {
    color: #fff;
    background: rgba(0,0,0,0);
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    z-index: 4;
    font-size: 24px;
    position: static;
    order: 2;
    margin-right: 10px;
    padding-bottom: 5px;
  }

.BlackboxTool-menu-header img.logo {
    width: 55%;
    margin: 40px auto;
    margin-left: 20px;
    position: relative;
    top: 5px;
}

.BlackboxTool-menu-items , .BlackboxTool-menu-settings, .BlackboxTool-menu-logout {
    width: 100%;
    margin: 0px auto;
    position: sticky;
}

.BlackboxTool-menu-settings {
    margin-top: 0%;
}

.BlackboxTool-menu-logout {
    position: absolute;
    bottom: 25px;
}

.BlackboxTool-menu-items a, .BlackboxTool-menu-settings a, .BlackboxTool-menu-logout a {
    all:unset;
    cursor: pointer;
}

.BlackboxTool-menu-items div, .BlackboxTool-menu-settings div, .BlackboxTool-menu-logout div {
    width: 100%;
    height: 40px;
    font-size: 14px;
    margin: 2px 0px;
    padding-left: 10%;
    display: flex;
    align-items: center;
    color: rgba(255,255,255, 0.8);
    box-sizing: border-box;
}

.BlackboxTool-menu-items div.active, .BlackboxTool-menu-settings div.active, .BlackboxTool-menu-logout div.active {
    background: rgb(34,34,42);
    color: white;
}

.customTheme .BlackboxTool-menu-items div.active, .customTheme .BlackboxTool-menu-settings div.active, .customTheme .BlackboxTool-menu-logout div.active {
    background: rgb(247, 168,0);
}

.BlackboxTool-menu-items div:hover, .BlackboxTool-menu-settings div:hover, .BlackboxTool-menu-logout div:hover {
    background: rgb(34,34,42);
    color: white;
}

.customTheme .BlackboxTool-menu-items div:hover, .customTheme .BlackboxTool-menu-settings div:hover, .customTheme .BlackboxTool-menu-logout div:hover {
    background: rgb(247, 168,0);
}

div.BlackboxTool-menu-items-titleHolder:hover {
    background: rgba(34,34,42,0);
    color: white;
}

.BlackboxTool-menu-items-item-icon {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BlackboxTool-menu-items-item-text {
    width: 90%;
    padding-left:10px;
}
  
  @media (max-width: 900px) {
  
    .BlackboxTool-menu {
        width: 100%;
    }

    .BlackboxTool-menu-header img.logo {
        width: 40%;
    }
      
  }