/* LandingPage.css */
.OurPromisesPage {
    font-family: 'Poppins', 'Montserrat', sans-serif;
    color: white;
    background: radial-gradient(circle at 35% 0%, rgba(44,45,49,1), rgba(16,19,22,1) 50%);
    backdrop-filter: blur(10px);
    width: 100%;
    margin: 0px auto;
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .OurPromisesPage-desktopSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 90%;
    margin: 200px auto;
  }

  .OurPromisesPage-desktopSection-mainTitle {
    font-weight: bolder;
    font-size: 1.8em ;
    z-index: 2;
  }
  
  .OurPromisesPage-desktopSection-tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    background: rgba(31,31,33, 0.7);
    box-sizing: border-box;
    padding: 20px;
  }
  
  .OurPromisesPage-desktopSection-tile-icon {
    font-size: 2.5em;
    color: rgba(255, 255,255,0.8);
    margin-bottom: 10px;
    margin-left: 10px;
  }
  
  .OurPromisesPage-desktopSection-tile-title {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .OurPromisesPage-desktopSection-tile-description {
    text-align: left;
    color: rgba(255,255,255,0.7);
    font-weight: 300;
    margin-bottom: 30px;
    font-family: 'calibri';
    font-size: 0.9vw;
  }

  .OurPromisesPage-mobileSection {
    display: none;
  }

  .OurPromisesPage-desktopSection-connectionSquare {
    position: absolute;
    top: -12vh;
    left: 15%;
    height: 50vh;
    width: 15%;
    background: linear-gradient(to right bottom, rgb(222,161,139), rgb(200,132,108));
    z-index: 0;
    border-radius: 5px;
    display: none;
  }

  /* Mobile ---------------------------------------------------------------------------*/
  @media all and (max-width: 1350px) {

    .OurPromisesPage-desktopSection {
      display: none;
    }

    .OurPromisesPage-mobileSection {
      display: flex;
    }


    .OurPromisesPage-mobileSection {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 80% !important;
      margin: 100px auto;
      box-sizing: border-box;
    }

    .OurPromisesPage-mobileSection-mainTitle {
      font-weight: bolder;
      z-index:2;
    }
  
    .OurPromisesPage-mobileSection-mainTitle-5ways{
      font-size: 36px;
      color: rgba(255,255,255,0.8);
      font-weight: bolder;
    }

    .OurPromisesPage-mobileSection-section h2 {
      margin-bottom: 26px;
    }

    .OurPromisesPage-mobileSection-tile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px;
      background: rgb(31,31,33);
      box-sizing: border-box;
      padding: 20px;
      width: 95%;
      height: 50vh;
      margin-bottom: 6vh;
    }
    
    .OurPromisesPage-mobileSection-tile-icon {
      font-size: 3em;
      color: rgba(255, 255,255,0.8);
      margin-bottom: 10px;
      margin-left: 10px;
    }
    
    .OurPromisesPage-mobileSection-tile-title {
      font-size: 1em;
      font-weight: bold;
      margin-bottom: 10px;
    }
    
    .OurPromisesPage-mobileSection-tile-description {
      text-align: left;
      color: rgba(255,255,255,0.7);
      font-weight: 300;
      margin-bottom: 30px;
      font-family: 'calibri';
      font-size: 0.9vw;
      letter-spacing: 0.17vw;
      width: 100%;
   
    }

    .OurPromisesPage-mobileSection-tile-description {
      font-size: 13px;
      letter-spacing: 2px;
    }

    .swiper-pagination-bullet {
      background-color: white;
    }
  }