.PersonaChatbot {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background: rgba(0,0,0,0.2);
}

.chat-container {
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
    overflow: hidden;
    margin: 0px;
    padding: 0px;
}

.chat-header {
    background: rgb(250,115,85);
    color: white;
    padding: 10px 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 20px 5px 15px rgba(0,0,0,0.1);
}

.customTheme .chat-header {
    background: rgb(205,32,38);
}

.chat-header p {
    position: relative;
    top: 5px;
}

.chat-box {
    height: 40vh;
    padding: 20px;
    background: rgba(0,0,0,0.2);
    color: white;
    overflow-y: auto;
    padding-top: 30px;
}

.persona-chat-message {
    width: 100%; /* Make sure the container takes up full width to keep messages left-aligned or right-aligned */
    text-align: left; /* Default alignment */
    margin-bottom: 15px; /* Add some space between messages */
}

.persona-chat-message.You {
    text-align: right; /* Align your messages to the right */
}

.message-content {
    display: inline-block; /* Make the width only as wide as needed */
    background-color: rgba(0,0,0,0.8); /* Or any other background you'd like */
    padding: 5px 10px; /* Some padding around the text */
    margin-left: 20px; /* Add some space between the edge of the message and the message content */
    border-radius: 8px; /* Rounded corners */
    max-width: 80%; /* Prevent extremely long messages from taking up full width */
    word-wrap: break-word; /* Break up very long words */
}

.persona-chat-message.You .message-content {
    background-color: rgba(41,41,51,1); /* Differentiate user messages with a different color, for example */
    color: white; /* If using a dark background color, make text light */
    margin-right: 20px; /* Align user messages to the right */
}

.chat-input {
    flex-direction: column;
    background: black;
    padding: 10px 20px;
}

.chat-input-interface {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chat-input-interface input {
    flex-grow: 1;
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    background: rgba(255,255,255,0.1);
    color: white;
}

.chat-input-interface button {
    background-color: rgba(255,255,255,0.1);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat-input-interface button:hover {
    background-color: rgba(0,0,0,0.5);
}

.chat-input-requestFeedback {
    margin-top: 10px;
    font-size: 12px;
    color: rgba(255,255,255,0.7);
    padding-left: 10px;
}



/* styling the scroll bar */

/* Styles for the entire scrollbar */
.chat-box::-webkit-scrollbar {
    width: 15px;  /* Adjust width for vertical scrollbar */
    height: 10px; /* Adjust height for horizontal scrollbar */
}

/* Styles for the thumb (the moving part) */
.chat-box::-webkit-scrollbar-thumb {
    background: rgba(41,41,51,0.8); /* Color of the scroll thumb */
    border-radius: 4px; /* Roundness of the scroll thumb */
}

/* Styles for the thumb on hover */
.chat-box::-webkit-scrollbar-thumb:hover {
    background: rgba(41,41,51,0.8); /* Color when hovered */
}

/* Styles for the track (the background) */
.chat-box::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.2); /* Color of the scroll track */
    border-radius: 4px; /* Roundness of the scroll track */
}

/* Styles for the track pieces that are visible when the scrollbar is in corner */
.chat-box::-webkit-scrollbar-corner {
    background: rgba(0,0,0,1); /* Color of the scroll corner */
}

.chat-box {
    scrollbar-width: thin; /* or "auto" or "none" or "thin" */
    scrollbar-color: rgba(41,41,51,0.8) rgba(0,0,0,0.2); /* thumb and track color */
}



