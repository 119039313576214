/* BlackboxPlayerPage.css */

/* Main Background */
.BlackboxPlayerPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    width: 100%;
    margin-top: 10vh;
}

.BlackboxPlayerPage-title {
    width: 100%;
    text-align: left;
    font-size: 28px;
}

.BlackboxPlayerPage-content {
    width: 100%;
}

.BlackboxPlayerPage-content-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
}

/* Player Info Styling */
.player-info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(41, 41, 51, 0.9);
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    width: 32%;
    transition: transform 0.3s ease;
    height: 370px;
}

.customTheme .player-info-card {
    background: rgba(41, 41, 51, 1);
}

.player-info-card.playerView {
    justify-content: center;
    position: relative;
}

.player-info-card.playerView Button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(0,0,0,0);
    color: white;
    border: none;
}

/* Player Image Styling */
.player-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #ffffff;
    margin-bottom: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.choose-newImageLabel {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8)
}

.player-info-card-title {
    width: 90%;
    margin-top: 20px;
}

.change-solutionImage-interface.playerProfile {
    width: 32%;
}

.player-info-card-title h2 {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8)
}

.player-info-card-graph {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.player-info-card-graph-doughnut-chart {
    width:200px !important;
    height: 200px !important;

}

.player-info-card-graph h1 {
    width: 100%;
    text-align: center;
    font-size: 80px;
    color: rgba(255, 255, 255, 1);
    position: absolute;
}

.player-info-card-legend{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.player-info-card-legend-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.player-info-card-legend-row-itemColor {
    width: 20px;
    height: 20px;
    border-radius: 10%;
}

.player-info-card-legend-row-itemText {
    width: 100%;
    padding-left: 15px;
    text-align: left;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

.player-info-card-legend-row-itemPercentage {
    color: rgba(255, 255, 255, 1);
}


@media (max-width:900px) {

    .BlackboxPlayerPage {
        margin-top: 1vh;
    }

    .BlackboxPlayerPage-content-row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .BlackboxPlayerPage-title {
        width: 95%;
        margin: 0 auto;
        text-align: left;
        font-size: 24px;
    }

    .player-info-card {
        width: 100%;
        margin: 20px auto;
    }

    .change-solutionImage-interface.playerProfile {
        width: 100%;
        margin-top: 20px;
    }
}

