.BlackboxCISolutions {
    width: 100%;
    margin-top: 12vh;
}

.BlackboxCISolutions-titleRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

.BlackboxCISolutions-titleRow-title {
    font-size: 28px;
    width: 50%;
    box-sizing: border-box;
  }

  .BlackboxCISolutions-titleRow-buttons {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

.BlackboxCISolutions-titleRow-filterButton {
    all: unset;
    cursor: pointer;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background:rgba(0,0,0,0.8);
    border-radius: 5px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
}

.BlackboxCISolutions-titleRow-shareButton {
    all: unset;
    cursor: pointer;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    background:rgb(250,115,85);
    border-radius: 5px;
    margin-left: 10px;
    height: 40px;
}

.customTheme .BlackboxCISolutions-titleRow-shareButton {
    background: rgb(205,32,38);
}

.BlackboxCISolutions-titleRow-filterButton:hover {
    background: rgba(0,0,0,0.5);
}

.BlackboxCISolutions-grid {
    width: 100%;
}

.BlackboxCISolutions-grid-tile {
    width: 95%;
    margin: 20px;
    background: rgba(46, 46, 56, 0.9);  /* semi-transparent background */
    background: rgba(46, 46, 56, 1);  /* semi-transparent background */
    backdrop-filter: blur(10px);  /* blur the background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.3);  /* dark border */
    border-radius: 10px 20px;
    box-sizing: border-box;
    padding-bottom: 10px;
    position: relative;
}

.BlackboxCISolutions-grid-tile-cardInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 50px;
    margin: 0px auto;
    border-bottom: 2px solid black;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
}

.BlackboxCISolutions-grid-tile-cardInfo-creator {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
}

/* Ideas Read Button */

.IdeasReadButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    border: none;
    font-size: 11px;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 5px;
    color: white;
}

.IdeasReadButton.Read {
    background: #68BB59;
}



.BlackboxCISolutions-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button.addToDeckButton {
    background: rgba(0,0,0,0.5);
    color: white;
    font-size: 18px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    right: auto;
    left: 20px;
}

.BlackboxCISolutions-grid-tile {
    width: 100%;
}

.BlackboxCISolutions-grid-tile * {
    overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word; /* Use for Chrome and Safari */
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}



.BlackboxCISolutions-grid-tile-title , .BlackboxCISolutions-grid-tile-solution , .BlackboxCISolutions-grid-tile-shortRecap, .BlackboxCISolutions-grid-tile-votingButtons, .BlackboxCISolutions-grid-tile-score , .BlackboxCISolutions-grid-tile-arguments, .BlackboxCISolutions-grid-tile-solution   {
    padding-left: 20px;
    padding-right: 20px;
}

.BlackboxCISolutions-grid-tile-shortRecap-readMore {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.BlackboxCISolutions-grid-tile-shortRecap-readMore-button {
    all: unset;
    cursor: pointer;
    text-align: left;
    color: white;
    font-weight: bold;
}

.BlackboxCISolutions-grid-tile-solution {
    margin-top: 20px;
}

.BlackboxCISolutions-grid-tile-solution h5 {
    font-size: 16px;
    font-weight: 800;
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 30px;
}

.BlackboxCISolutions-grid-tile-votingSlider {
    padding-left: 20px;
    padding-right: 20px;
}

.BlackboxCISolutions-grid-tile-votingSlider input {
    width: 90%;
    height: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    background-size: 200% 100%;
    background-position: right bottom;
    margin-top: 20px;
}

.BlackboxCISolutions-grid-tile-votingSlider label {
    margin-left: 10px;
}

.BlackboxCISolutions-grid-tile-image {
    padding: 20px;
    padding-bottom: 10px;
}

.BlackboxCISolutions-grid-tile-title h4 {
    font-size: 18px;
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 30px;
}

.BlackboxCISolutions-grid-tile-image img {
    width: 100%;
}

.changeImageButton {
    all: unset;
    position: absolute;
    right: 40px;
    top: 70px;
    cursor: pointer;
    background: rgba(0,0,0,1);
    color: white;
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 10px;
}
.change-solutionImage-interface {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    background: rgba(0,0,0,0.5);
    border-radius: 10px;
    z-index: 100;
    padding:0;
}

.SpinnerSymbol {
    width: 16px;
    height: 16px;
}

.image-dropperContainer {
    width: 80%;
    position: relative;
    top: -3px;
    min-height: 100px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image-dropperContainer.idea-sharingModal {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    top: -20px;
}

.image-dropperContainer-chooseFileButton {
    color: white;
    height: 50px;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px 0px 0px 5px;
    margin-top: 10px;
}

.idea-sharingModal .image-dropperContainer-chooseFileButton {
    background: black;
    border: solid 1px black;
}

.image-dropperContainer-image-drop-zone {
    width: 60%;
    height: 50px;
    padding-left: 1px;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid grey;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    overflow: hidden;
}

.idea-sharingModal .image-dropperContainer-image-drop-zone {
    border: 1px solid black;
    background: rgba(0,0,0,0.3);
    color: rgba(255,255,255,0.8);
}

.image-dropperContainer-saveButton {
    width: 50px;
    height: 50px;
}


.BlackboxCISolutions-grid-tile-votingButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.BlackboxCISolutions-grid-tile-votingButtons button {
    all: unset;
    cursor: pointer;
    width: 48%;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    background:rgba(0,0,0,0.5);
}

.BlackboxCISolutions-grid-tile-votingButtons button.inactive {
    background:rgba(0,0,0,0.1);
    opacity: 0.5;
}



.BlackboxCISolutions-grid-tile-votingButtons button:hover {
    background:rgba(0,0,0,0.9);
}

.BlackboxCISolutions-grid-tile-score {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;
    margin-top: 50px;
}

.BlackboxCISolutions-grid-tile-score.inactive {
    display: none;
}

.BlackboxCISolutions-grid-tile-arguments {
    margin-top: 10px;
}

.BlackboxCISolutions-grid-tile-arguments-unfoldButton {
    all: unset;
    cursor: pointer;
    width: 100%;
    height: 32px;
    border-radius: 3px;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    background:rgba(0,0,0,0);
}

.BlackboxCISolutions-grid-tile-arguments button:hover {
    font-weight: bold;
}

.BlackboxCISolutions-grid-tile-arguments-pro-argument, .BlackboxCISolutions-grid-tile-arguments-con-argument  {
    display:flex;
    flex-direction: row;
    margin: 10px auto;
}

.BlackboxCISolutions-grid-tile-arguments-pro-argument-icon , 
.BlackboxCISolutions-grid-tile-arguments-con-argument-icon {
    margin-right: 10px;
}

.BlackboxCISolutions-grid-tile-arguments-shareButton {
    all: unset;
    cursor: pointer;
    width: 100%;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    background:rgba(0,0,0,0.5);
    margin-top: 20px;
}

.BlackboxCISolutions-grid-tile .InspiredBySection {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.BlackboxCISolutions-grid-tile .InspiredBySection h4 {
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
}

.BlackboxCISolutions-grid-tile .InspiredBySection-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
}

.BlackboxCISolutions-grid-tile .InspiredBySection-items-item {
    font-size: 12px;
    padding: 10px; 
    border-radius: 5px;
    background: rgba(0,0,0,0.5);
    width: 33%;
    margin-right: 2%;
    display: flex;
    flex-direction: row;
}

.BlackboxCISolutions-grid-tile .InspiredBySection-items-item .InspiredBySection-items-item-imageContainer {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.BlackboxCISolutions-grid-tile .InspiredBySection-items-item div img {
    width: 100%;
}

.BlackboxCISolutions-grid-tile .InspiredBySection-items-item .InspiredBySection-items-item-textContainer {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 10px;
    font-size: 8px;
    
}

.BlackboxCISolutions-grid-tile .InspiredBySection-items-item .InspiredBySection-items-item .InspiredBySection-items-item-textContainer p {
    margin: 0px;
    padding: 0px;
    color: white;
    font-size: 8px;
}




.BlackboxCISolutions-shareSolutionsModal {
    background: rgba(0,0,0,0.8);
    color: white;
}

.BlackboxCISolutions-shareSolutionsModal .modal-content {
    background: rgb(34,34,42);
}

.BlackboxCISolutions-shareSolutionsModal-header  {
    background: rgb(250,115,85);
    color: white;
}

.BlackboxCISolutions-shareSolutionsModal-header.customTheme   {
    background: rgb(205,32,38);
}

.BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection {
    width: 100%;
}

.BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection h4 {
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
}

.BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;

}

.BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection-items-item {
    font-size: 12px;
    padding: 10px; 
    border-radius: 5px;
    background: rgba(0,0,0,0.5);
    width: 33%;
    margin-right: 2%;
    display: flex;
    flex-direction: row;
}

.BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection-items-item img {
    width: 15%;
}
.BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection-items-item div {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 10px;
    font-size: 10px;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputLabel {
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
}

.BlackboxCISolutions-shareSolutionsModal-body-topics {
    font-size: 14px;
    background: rgba(0,0,0,0.5);
    padding: 10px 10px;
    border-radius: 5px;
    margin-top: 5px;
}


.BlackboxCISolutions-shareSolutionsModal-header button.close {
    color: #fff !important;
    fill: #fff !important;
    opacity: 1 !important;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputField {
    background-color: rgba(0,0,0,0.5) !important;
    color: white !important;
    border: none !important;
    margin-bottom: 10px !important;
    height: auto !important; /* Overrides any set height */
    min-height: 0 !important; /* In case Bootstrap or other CSS sets a min-height */
    max-height: none !important; /* In case there's a max height set somewhere */
    font-size: 14px;
    margin-top: 5px;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputField .ql-container {
    border: none !important;
    padding-bottom: 30px !important;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputField .ql-toolbar {
    background: black !important;
    border: none !important;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputField .ql-toolbar button {
    color: white !important;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputField .ql-toolbar .ql-picker-label {
    color: white !important;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputField .ql-toolbar .ql-picker-item {
    color: white !important;
    font-size: 12px;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputField .ql-editor {
    border: none !important;
}

.BlackboxCISolutions-shareSolutionsModal-body-inputField .ql-editor.ql-blank::before {
    color: rgba(255,255,255,0.5) !important;
}


.BlackboxCISolutions-shareSolutionsModal-body-inputField::placeholder {
    color: rgba(255,255,255,0.5) !important;
}

.BlackboxCISolutions-shareSolutionsModal-footer-submitButton {
    background-color: rgba(0,0,0,0.5) !important;
    color: white !important;
    border-color: black !important;
}

.BlackboxCISolutions-shareSolutionsModal-footer-submitButton:hover {
    background-color: rgba(0,0,0,0.9) !important;
}

.BlackboxCISolutions-grid-tile-votingTitle {
    margin-top: 50px;
    margin-bottom: 10px;
    width: 100%;
}

.BlackboxCISolutions-grid-tile-votingTitle p {
    width: 90%;
    margin: 10px auto;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}


/* Modal settings*/
Button.randomSolutionGeneratorButton {
    all: unset;
    cursor: pointer;
    padding: 5px 10px;
    background: black;
    color: white;
    margin-left: 50px;
    font-size: 12px;
    position: absolute;
    right: 50px;
    height: 25px;
    border-radius: 5px;
}

Button.randomSolutionGeneratorButton:hover {
    background: rgba(0, 0, 0, 0.5);
}

Button.randomSolutionGeneratorButton:disabled {
    background: rgba(0, 0, 0, 0.3);
}

Button.randomSolutionGeneratorButton:active,
Button.randomSolutionGeneratorButton:focus {
    background: black !important;
    color: white;
    outline: none;  /* to remove any default browser outlines */
    box-shadow: none; /* to remove Bootstrap's blue glow */
}



.randomSolutionSolutionButtonIcon {
    margin-right: 10px;
}

/* Styling of the solutions tyle - NeedVoting*/

.BlackboxCISolutions-grid-tile-votingTitle {
    border-top: solid 2px black;
}

.BlackboxCISolutions-grid-tile-votingTitle p {
    margin-top: 20px;
}

/* Styling of the solutions tyle - Arguments*/
.BlackboxCISolutions-grid-tile-arguments-pro {
    width: 100%;
}

.BlackboxCISolutions-grid-tile-arguments-pro-argument {
    width: 100%;
    margin-bottom: 20px;
}

.BlackboxCISolutions-grid-tile-arguments-pro-argument-text {
    width: 80%; 
}

.argument-likes {
    display: flex;
    flex-direction: column;
    width: 10%;
    margin: 0px auto;
    margin-left: 5%;
}
.argument-likes button {
    background: rgba(0,0,0,0);
    border: none;
    color: grey;
    width: 30px;
    display: flex;
    flex-direction: row;
}

.argument-likes button.liked {
    border: none;
    color: white;
}


/* Code for spinning load button */

.loadingSpinner {
    margin-right: 6px;
}

@media (max-width:900px) {

    .BlackboxCISolutions {
        margin-top: 1vh;
    }
    

    .BlackboxCISolutions-titleRow {
        width: 90%;
        margin: 0px auto;
        flex-direction: column;
    }
    
    .BlackboxCISolutions-titleRow-title {
        font-size: 24px;
        width: 100%;
      }
    
      .BlackboxCISolutions-titleRow-buttons {
        width: 100%;
        margin: 10px auto;
        justify-content: space-between;
        align-items: center;
      }
    
    .BlackboxCISolutions-titleRow-filterButton {
        margin-right: auto;
        background: rgba(0,0,0,1);
    }
    
    .BlackboxCISolutions-titleRow-shareButton {
        margin-left: auto;
        background: rgba(0,0,0,1);
    }

    .BlackboxCISolutions-grid-tile {
        width: 95%;
        margin: 20px auto;
    }

}