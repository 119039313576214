.BlackboxRankingPage {
  color: #fff;
  width: 80vw !important;
}

.table {
  width: 90%;
  margin: auto;
}

.table td, .table th {
  border-color: rgb(34,34,42);
  text-align: center;
  vertical-align: middle;
  background: rgb(41,41,51);
}

.table th.sortable {
  cursor: pointer;
}

.profile-pic {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.highlight td, .highlight th {
  background: rgb(34,34,42) !important;
}