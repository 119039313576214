.Footer { 
  height:auto;
  background-color: black;
  color: white; 
  font-family: 'calibri', Arial, sans-serif; 
}

.Footer h1.footerTitle {
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  text-align: left;
  margin-bottom: 20px;
}

.Footer-content {
  height: 100%;
  width: 86%;
  margin: 0px auto;
  padding: 100px 0px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  font-size: 1vw;
  letter-spacing: 0.17vw;
}

.Footer-content-description {
  width: 40%;
  text-align: left;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
}

.Footer-content-links {
  width: 30%;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
}

.Footer-content-links a {
  margin-top: 5px;
  cursor: pointer;
  letter-spacing: 2px;
}

.Footer-content-links h1 {
  letter-spacing: 3px;
}

.Footer-content-logins {
  width: 30%;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
}

.Footer-content-logins h1 {
  letter-spacing: 3px;
}

.Footer-content-logins a {
  margin-top: 5px;
  cursor: pointer;
  letter-spacing: 2px;
}

.footerDeviderLine{
  width: 86%;
  margin: 20px auto;
  background-color: #fff;
}

.Footer-copyrightAndSocials{
  width: 86%;
  margin: 0px auto;
  padding: 20px 0px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Footer-copyrightAndSocials-copyright {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 60%;
}

.Footer-copyrightAndSocials-socials{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
}

.Footer-copyrightAndSocials-socials a{
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 90%;
  font-size: 20px;
  color: white;
  margin-left: 20px;
  display: flex;
  justify-content: center; 
  align-items: center;
}

.Footer-content-logins .loginButton {
  all: unset;
  width: 90%;
  height: 30px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 13px;
}

.Footer-content-logins .loginButton:hover {
  font-weight: bold;
}

.Footer-content-logins .appointmentButton {
  all: unset;
  cursor: pointer;
  width: 90%;
  height: 30px;
  border: 1px solid white;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  font-size: 13px;
}

.Footer-content-logins .appointmentButton:hover {
  font-weight: bold;
}

.Footer-content-links a {
  color: white;
}

.Footer-content-links a:hover {
  color: white;
  font-weight: bolder;
}



  /* Mobile ---------------------------------------------*/

  @media (max-width: 1350px) {

    .Footer-content-description {
      display: none;
    }

    .Footer-content-links {
      width: 50%;
    }

    .Footer-content-links h1 {
      font-size: 14px !important;
      letter-spacing: 2px !important;
      font-weight: 600 !important;
    }

    .Footer-content-links a {
      font-size: 12px;
    }

    .Footer-content-logins {
      width: 50%;
    }

    .Footer-content-logins h1 {
      font-size: 14px !important;
      letter-spacing: 2px !important;
      font-weight: 600 !important;
    }


    .Footer-content-logins a {
      font-size: 12px;
    }

    .Footer-content-logins .loginButton {
      font-size: 12px;
    }

    .Footer-content-logins .registerButton {
      font-size: 12px;
    }



    .copyright-text {
      font-size: 12px;
    }

    .Footer h1.footerTitle {
      font-size: 20px;
      font-weight: 400;
    }

    .Footer-copyrightAndSocials-socials a {
      width: 25px;
      height: 25px;
      font-size: 14px;
      margin-left:10px;
    }
    
  }
  